import create from 'zustand';
import useStore from 'store';
import useUserStore from 'store/user';
import {
  EDITOR_ROLES,
  AUTHOR_ROLES,
  PUPIL_ROLES,
  TEACHER_ROLES,
  PARENT_ROLES,
  SCHOOL_ADMIN_ROLES,
  INTERCOM_ENABLED,
} from './constants';

const { hasConnectionData, clear: clearStore } = useStore.getState();
const { clear: clearUserStore } = useUserStore.getState();

export default create((set, get) => ({
  jwt: null,
  user: null,
  intercomEnabledForUser: false,
  role: null,
  iframe: null,
  temporaryPassword: null,
  username: null,
  requiresSocket: true,
  requiresData: true,
  getJwt: () => get().jwt,
  getIframe: () => get().iframe,
  setTemporaryPassword: (temporaryPassword) => set({ temporaryPassword }),
  setUsername: (username) => set({ username }),
  setJwt: (jwt) =>
    set(() => {
      const { primaryUsers = [], user = null } = jwt
        ? JSON.parse(atob(jwt.split('.')[1]))
        : {};

      const isLoggedInAs = primaryUsers.length > 0;
      const intercomEnabledForUser =
        INTERCOM_ENABLED && user?.subtype === 'employee' && !isLoggedInAs;

      let requiresSocket = false;
      let requiresData = false;

      const { user: currentUser, isParent, isTeacher, isPupil } = get();

      const userChange = currentUser?._id !== user?._id;
      const userTypeChange = currentUser?.subtype !== user?.subtype;
      const emailVerifiedChange =
        currentUser?.emailVerified !== user?.emailVerified;

      const currentFamilyIds = [...(currentUser?.familyIds || [])]
        .sort()
        .toString();
      const familyIds = [...(user?.familyIds || [])].sort().toString();
      const schoolOrFamiliesChange =
        currentUser?.schoolId !== user?.schoolId ||
        currentFamilyIds !== familyIds;

      if (
        userChange ||
        userTypeChange ||
        emailVerifiedChange ||
        ((isParent() || isTeacher()) && schoolOrFamiliesChange)
      ) {
        requiresSocket = true;
        requiresData = true;
        if (userChange) {
          clearStore();
          clearUserStore();
        }
      } else if (isPupil && schoolOrFamiliesChange) {
        requiresSocket = true;
        requiresData = !hasConnectionData;
      }

      set({ jwt, user, requiresSocket, requiresData, intercomEnabledForUser });
    }),
  setRequiresData: (requiresData) => set({ requiresData }),
  setIframe: (iframeEle) => set({ iframe: iframeEle }),
  hasRole: (roles) =>
    roles.some((role) => (get().user?.roles || []).includes(role)),
  isEditor: () => get().hasRole(EDITOR_ROLES),
  isAuthor: () => get().hasRole(AUTHOR_ROLES),
  isPupil: () => get().hasRole(PUPIL_ROLES),
  isParent: () => get().hasRole(PARENT_ROLES),
  isTeacher: () => get().hasRole(TEACHER_ROLES),
  isSchoolAdmin: () => get().hasRole(SCHOOL_ADMIN_ROLES),
  hasSchool: () => !!get().user?.schoolId,
}));
