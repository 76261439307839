import { Switch, Route, Redirect } from 'react-router-dom';
import NetworkIssue from 'components/network-issue';

function ErrorRoutes() {
  return (
    <Switch>
      <Route path="/network-issue" exact>
        <NetworkIssue />
      </Route>
      <Route>
        <Redirect to="/network-issue" />
      </Route>
    </Switch>
  );
}

export default ErrorRoutes;
