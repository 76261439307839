import { useEffect } from 'react';
import useStore from 'store';

const PING_INTERVAL = 60_000;

const useKeepWebsocketAlive = () => {
  const socket = useStore((store) => store.socket);

  useEffect(() => {
    // Reduce log noise by excluding PING/PONG
    socket?.addLogFilter(
      ({ data }) => data?.action !== 'PING' && data?.action !== 'PONG'
    );

    const sendPing = () => socket?.send('PING');
    setInterval(sendPing, PING_INTERVAL);
    return () => clearInterval(sendPing);
  }, [socket]);
};

export default useKeepWebsocketAlive;
