/* eslint-disable no-param-reassign */
const getInitialState = () => ({
  isJoiningInstitution: null,
  schoolToken: null,
  invitations: null,
});

const invitationsSlice = (set, get) => ({
  sliceName: 'invitations',
  getInitialState,
  onConnected: (data) => {
    const { invitations, schoolToken } = data;
    if (invitations) get().setInvitations(invitations);
    if (schoolToken) get().setSchoolToken(schoolToken);
  },
  selectors: {
    ...getInitialState(),
  },
  mutators: {
    withdrawInvitation: (invitationId) =>
      set((draft) => {
        draft.invitations = draft.invitations.filter(
          (inv) => inv._id !== invitationId
        );
      }),
    issueInvitation: (invitation) =>
      set((draft) => {
        draft.invitations.push(invitation);
      }),
  },
});

export default invitationsSlice;
