/* eslint-disable no-param-reassign */
import formatRelativeDate from 'helpers/format-relative-date';
import { compareTags } from 'pages/teacher-app/pupils/pupils-table/helpers/pupil-sort-functions';

const sortPupils = (a, b) => a.lastName.localeCompare(b.lastName);

const getInitialState = () => ({
  allTags: new Set(),
  pupils: null,
  pupilsList: [],
  goalsTarget: 0,
  pupilsProgressLoading: true,
  pupilsPracticeResultsLoading: true,
});

const setConvenienceFields = (pupil) =>
  Object.assign(pupil, {
    pupilId: pupil.id,
    fullName: `${pupil.firstName} ${pupil.lastName}`,
    lastActiveText: formatRelativeDate(pupil.lastActive, new Date()),
    pendingPlan: pupil.paymentPlan,
  });

const pupilsSlice = (set, get) => ({
  sliceName: 'pupils',
  getInitialState,
  onConnected: (data) => {
    const { pupils, goalsTarget } = data;
    const { setPupils, setGoalsTarget } = get();
    if (pupils) setPupils(pupils);
    if (goalsTarget) setGoalsTarget(goalsTarget.value);
  },
  selectors: {
    ...getInitialState(),
  },
  mutators: {
    setPupilTags: (pupils) =>
      set((draft) => {
        pupils.forEach((pupil) => {
          (pupil.tags ?? []).forEach((tag) => draft.allTags.add(tag));
        });
      }),

    addPupilTag: (tagName) =>
      set((draft) => {
        draft.allTags.add(tagName);
      }),

    tagOrUntagPupils: (pupilIds, tagName, isUntag) =>
      set((draft) => {
        pupilIds.forEach((id) => {
          const pupil = draft.pupils[id];
          if (pupil) {
            const tags = new Set(pupil.tags ?? []);
            tags[isUntag ? 'delete' : 'add'](tagName);
            pupil.tags = [...tags].sort(compareTags);
            if (!isUntag) draft.allTags.add(tagName);
            // Don't remove tags from the list - the teacher may still be using them.
          }
        });
      }),

    tagPupils: (pupilIds, tagName) => {
      get().tagOrUntagPupils(pupilIds, tagName);
    },

    untagPupils: (pupilIds, tagName) => {
      get().tagOrUntagPupils(pupilIds, tagName, true);
    },

    setPupils: (pupils) =>
      set((draft) => {
        for (const pupil of pupils) pupil.tags?.sort(compareTags);

        draft.pupils = Object.fromEntries(
          pupils.map((pupil) => [pupil.id, setConvenienceFields({ ...pupil })])
        );

        const tags = pupils.flatMap((p) => p.tags ?? []);
        draft.allTags = new Set(tags);

        // Maintain a simple list of pupils that doesn't cause state
        // updates when pupil progress is batch loaded.
        draft.pupilsList = pupils.sort(sortPupils).map((pupil) => ({
          id: pupil.id,
          name: `${pupil.firstName} ${pupil.lastName}`,
        }));
      }),

    removePupil: (id) =>
      set((draft) => {
        delete draft.pupils[id];
      }),

    setPupil: (id, pupil /* , isNew ? */) =>
      set((draft) => {
        draft.pupils[id] = setConvenienceFields({ id, ...pupil });
        for (const tag of pupil.tags ?? []) draft.allTags.add(tag);
      }),

    setPupilsProgress: (pupilsProgress) =>
      set((draft) => {
        for (const [id, progress] of Object.entries(pupilsProgress)) {
          const pupil = draft.pupils[id];
          if (!pupil) continue; // TODO: handle error

          Object.assign(pupil, progress);
          setConvenienceFields(pupil);
          pupil.hasProgress = true;
        }
        const pupils = Object.values(draft.pupils);
        draft.pupilsProgressLoading = pupils.some((p) => !p.hasProgress);
      }),

    setPupilsPracticeResults: (pupilsPracticeResults) =>
      set((draft) => {
        for (const [id, results] of Object.entries(pupilsPracticeResults)) {
          const pupil = draft.pupils[id];
          if (!pupil) continue; // TODO: handle error

          pupil.practice = results;
          pupil.hasPracticeResults = true;
        }
        const pupils = Object.values(draft.pupils);
        draft.pupilsPracticeResultsLoading = pupils.some(
          (p) => !p.hasPracticeResults
        );
      }),

    setGoalsTarget: (target) =>
      set((draft) => {
        const numGoals = parseInt(target, 10);
        draft.goalsTarget = isNaN(numGoals) ? 0 : numGoals;
      }),

    setGoalCompleted: (goalId, pupilId, completionDate) =>
      set((draft) => {
        const currentPupil = draft.pupils[pupilId];
        if (currentPupil) {
          currentPupil.completedGoals ??= [];
          currentPupil.completedGoals.push({ completionDate, goalId });
        }
      }),

    setTemporaryPassword: (pupilId, tempPassword) =>
      set((draft) => {
        const currentPupil = draft.pupils[pupilId];
        if (currentPupil) {
          currentPupil.temporaryPassword = tempPassword;
        }
      }),
  },
});

export default pupilsSlice;
