/* eslint-disable no-param-reassign */
import { upperFirst } from 'helpers/text-case';
import awards from './awards';
import courses from './courses';
import invitations from './invitations';
import jsonFiles from './json-files';
import memoryBoost from './memory-boost';
import focusFive from './focus-five';
import notifications from './notifications';
import parents from './parents';
import practice from './practice';
import pupils from './pupils';
import pupilMemberships from './pupil-memberships';
import quizzes from './quizzes';
import socket from './socket';
import staff from './staff';
import ui from './ui';

const sliceDefinitions = {
  awards,
  courses,
  invitations,
  jsonFiles,
  memoryBoost,
  focusFive,
  notifications,
  parents,
  practice,
  pupils,
  pupilMemberships,
  quizzes,
  socket,
  staff,
  ui,
};

const getSlices = (set, get) =>
  Object.values(sliceDefinitions).map((slice) => slice(set, get));

const generateMutators = (names, set) => {
  return names.reduce((mutators, name) => {
    mutators[`set${upperFirst(name)}`] = (value) =>
      set((draft) => {
        draft[name] = value;
      });
    return mutators;
  }, {});
};

const createCoreStore = (slices, set) =>
  Object.assign(
    ...slices.map(
      ({ selectors = {}, mutators = {}, getInitialState = getEmpty }) => {
        const properties = Object.keys(getInitialState());
        return Object.assign(
          selectors,
          generateMutators(properties, set),
          mutators
        );
      }
    )
  );

const getEmpty = () => ({});

const getCombinedInitialState = (slices) =>
  Object.assign(
    ...slices.map(({ getInitialState = getEmpty }) => getInitialState())
  );

const combineConnectedHandlers = (slices) => (data) => {
  const isReady = {};
  let waiting = [];
  slices.forEach(({ onConnected, onSlicesReady = [], sliceName }) => {
    if (onConnected) onConnected(data);
    isReady[sliceName] = true;
    waiting = waiting.concat(onSlicesReady);
    if (waiting.length) {
      waiting = waiting.reduce((stillWaiting, deferredFn) => {
        if (!deferredFn.dependencies.every((dep) => isReady[dep])) {
          stillWaiting.push(deferredFn);
        } else {
          deferredFn.run(data);
        }
        return stillWaiting;
      }, []);
    }
  });
};

const getCombinedStore = (set, get, connectionTransforms = []) => {
  const slices = getSlices(set, get);
  const initialState = getCombinedInitialState(slices);

  const transform = (data) =>
    connectionTransforms.reduce((soFar, fn) => fn(soFar), data);

  return {
    ...createCoreStore(slices, set),
    clear: () =>
      set((draft) => {
        Object.entries(initialState).forEach(([key, value]) => {
          draft[key] = value;
        });
      }),
    processOnConnectedData: (data) =>
      combineConnectedHandlers(slices)(transform(data)),
  };
};

export default getCombinedStore;
