/* eslint-disable no-param-reassign */
const getInitialState = () => ({ awards: {} });

const awardsSlice = (set, get) => ({
  sliceName: 'awards',
  getInitialState,
  onConnected: (data) => {
    const { awards } = data;
    if (awards) get().setAwards(awards);
  },
  selectors: {
    ...getInitialState(),
  },
  mutators: {
    setAwardProgress: (data) =>
      set((draft) => {
        const award = draft.awards[data.awardName];
        if (award) {
          award.nextThresholdXP = data.nextThresholdXP;
          award.totalXP = data.totalXP;
          award.level = data.level;
          award.lastUpdated = data.lastUpdated;
        }
      }),
  },
});

export default awardsSlice;
