import React from 'react';
import ReactDOM from 'react-dom';
import { IntercomProvider } from 'react-use-intercom';
import TagManager from 'react-gtm-module';
import GA4React from 'ga-4-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Amplify from 'aws-amplify';
import { clarity } from 'react-microsoft-clarity';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
// Add fromEntries for now because Babel fails to polyfill Object.fromEntries on iOS 11
import fromEntries from 'object.fromentries';
import App from 'pages/app';
import { defaultTheme } from './styles';
import createTheme from './styles/create-theme';
import './styles/fonts.css';
import 'katex/dist/katex.min.css';
import 'react-app-polyfill/stable';

fromEntries.shim(); // will be a no-op if not needed

// Configure Sentry.
const qs = new URLSearchParams(window.location.search);

if (
  process.env.REACT_APP_ENABLE_SENTRY === 'true' &&
  !qs.get('previewCourseId')
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    allowUrls: [/^https?:\/\/.+\.completemaths\.com/],
  });
}

// Configure AWS Amplify.
Amplify.configure({
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_AWS_API_NAME,
        endpoint: process.env.REACT_APP_AWS_APIGATEWAY_URL,
        region: process.env.REACT_APP_AWS_REGION,
      },
    ],
  },
});

// Configure Google Analytics 4
(async () => {
  try {
    const ga4react = new GA4React(process.env.REACT_APP_GA4_ID);
    const ga4 = await ga4react.initialize();
    ga4.send({ hitType: 'pageview', page: window.location.pathname });
  } catch (err) {
    // catch error if google analytics fails to initialise (ie addblocker blocks it)
  }
})();

// Configure Google Tag Manager
TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH_ID,
  preview: process.env.REACT_APP_GTM_PREVIEW_ID,
  dataLayer: {
    event: 'pageview',
  },
});

// Configure Clarity
if (process.env.REACT_APP_ENABLE_CLARITY === 'true') {
  clarity.init(process.env.REACT_APP_CLARITY_APP_ID);
}

ReactDOM.render(
  <React.StrictMode>
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </IntercomProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
