const apiGatewayHost = process.env.REACT_APP_AWS_APIGATEWAY_URL;

export const API_ENDPOINTS = {
  GET_COST_SUMMARY: `${apiGatewayHost}stripe/cost-summary`,
  UPDATE_SUBSCRIPTION: `${apiGatewayHost}stripe/update-subscription`,
  GET_PAYMENT_METHODS: `${apiGatewayHost}stripe/manage-payments`,
  CREATE_CUSTOMER_PORTAL_SESSION: `${apiGatewayHost}stripe/create-customer-portal-session`,
};

// Manage Subscriptions
export const TUTOR_PAYMENT_PLANS = {
  SCHOOL_SPONSORED: 'SCHOOL_SPONSORED',
  SCHOOL_DISCOUNTED: 'SCHOOL_DISCOUNTED',
  ALREADY_SUBSCRIBED: 'ALREADY_SUBSCRIBED',
  STANDARD_FAMILY: 'STANDARD_FAMILY',
  NONE: 'NONE',
  MAKE_SELECTION: 'MAKE_SELECTION',
};

export const PAYMENT_METHODS = {
  NEW: 'New Payment Method',
};

export const MANAGE_SUBSCRIPTION_ERRORS = {
  UPDATE_SUBSCRIPTION: 'Update Subscription Error',
  COST_SUMMARY: 'Cost Summary Error',
  PAYMENT_METHODS: 'Load Methods Error',
  CONFIRM_SETUP: 'Confirm Setup Error',
  NETWORK_ERROR: 'Network Error',
  UNKNOWN: 'Unknown Error',
};

export const MANAGE_SUBSCRIPTION_STEPS = {
  SELECT: {
    title: 'Select Account Types',
    intro:
      'Does your child need a new TUTOR account, or do they have an existing account through their school or another family?',
  },
  BUILD: {
    title: 'Build Subscription',
    intro: 'Confirm below which Child accounts you want to have TUTOR Access.',
  },
  CONFIRM: {
    title: 'Confirmation',
    intro:
      'Review your subscription and billing details below, and click Confirm Subscription to proceed.',
  },
};
