export const onFocusFiveGetSuccess = (data, get) => {
  get().setFocusFive(data); // focus-five slice
};

export const onFocusFiveGetGoalsSuccess = (
  { focusFiveGoalIds, hasDiagnosticStarted, sequenceId },
  get
) => {
  get().setFocusFiveGoals({
    hasDiagnosticStarted,
    goalIds: focusFiveGoalIds,
    sequenceId,
  });
};

export const onFocusFiveUpdateSuccess = (
  { focusFiveGoalIds, sequenceId },
  get
) => {
  get().setFocusFiveGoals({
    goalIds: focusFiveGoalIds,
    sequenceId,
  });
};

export const onGetTutorialQuestionsSuccess = (data, get) => {
  get().setTutorial({
    id: data.tutorialId,
    questions: data.tutorialQuestions,
    currentQuestionIndex: data.currentQuestionIndex ?? 0,
  });
};

export const onGetMathsQuestionSuccess = (data, get) => {
  get().setMathsQuestion({ ...data.mathsQuestion });
};

export const onAnswerTutorialQuestionSuccess = (data, get) => {
  if (data.answerIndex !== -1) {
    setTimeout(() => {
      get().incrementTutorialQuestionIndex();
    }, 2000);
  } else {
    get().incrementTutorialQuestionIndex();
  }
};

export const onAnswerDiagnosticQuestionSuccess = (data, get) => {
  get().setHasDiagnosticsFinished(data.hasDiagnosticsFinished);
  // setTimeout(() => get().incrementDiagnosticQuestionIndex(), 1000);
  get().incrementDiagnosticQuestionIndex();
};

export const onGetDiagnosticRoundSuccess = (data, get) => {
  get().setDiagnosticRound({
    id: data.roundId,
    roundCount: data.roundCount,
    // TODO: Swap commented line for fast testing of Focus 5
    questions: data.questions,
    // questions: initialRound ? data.questions : [],
    currentQuestionIndex: 0,
  });
  get().setHasCompletedIntroduction(true);
  if (!data.questions?.length) {
    get().setHasDiagnosticsFinished(true);
  }
};

export const onResetFocusFiveDiagnosticsSuccess = (data, get) => {
  get().resetFocusFiveDiagnostics();
};
