import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      width: '229px',
      height: '78px',
    },
  },
});

export default makeStyles(styles);
