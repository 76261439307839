/* eslint-disable no-param-reassign */
import {
  TUTOR_PAYMENT_PLANS,
  PAYMENT_METHODS,
} from 'app-constants/family-subscriptions';

const { NONE, MAKE_SELECTION, ALREADY_SUBSCRIBED } = TUTOR_PAYMENT_PLANS;

const addDefaultsForPendingChildren = (pendingChildren = []) =>
  Object.fromEntries(
    pendingChildren.map((child) => [
      child.id,
      {
        ...child,
        pendingPupilId: child.id,
        paymentPlan: child.paymentPlan ?? 'NONE',
        pendingPlan: child.paymentPlan ?? 'NONE',
        tutorAccessStatus: child.tutorAccessStatus ?? 'Pending',
      },
    ])
  );

const getInitialState = () => ({
  selectedChildInFamilyId: undefined,
  previewChildId: undefined,
  familyId: undefined,
  familyName: '',
  orders: undefined,
  parentsInFamily: [],
  invitationsFromFamily: [],
  pendingChildren: {},
  paymentPlans: {},
  paymentCycleDate: undefined,
  monthlyCharge: undefined,
  paymentMethods: [],
  currentPaymentMethod: PAYMENT_METHODS.NEW,
  customerId: undefined,
  subscriptionId: undefined,
});

const parentsSlice = (set, get) => ({
  sliceName: 'parents',
  getInitialState,
  onConnected: (data) => {
    const { initPreviewChild, initFamily, setPaymentPlans } = get();
    const { previewChild, families, paymentPlans } = data;

    if (previewChild) initPreviewChild(previewChild);
    else if (families?.[0]) {
      initFamily(families[0]);
      setPaymentPlans(paymentPlans ?? {});
    }
  },
  onSlicesReady: [
    {
      dependencies: ['pupils'],
      run: ({ families, previewChild }) => {
        if (families?.[0]?.pupils)
          get().setPupils(Object.values(families[0].pupils));
        if (previewChild) get().setGoalsTarget(previewChild.goalsTarget?.value);
      },
    },
    {
      dependencies: ['courses'],
      run: ({ previewChild }) => {
        if (previewChild)
          get().setEnrolledCourses(previewChild.enrolledCourses ?? []);
      },
    },
  ],
  selectors: {
    ...getInitialState(),
    getFamilyId: () => get().familyId,
  },
  mutators: {
    initPreviewChild: (previewChild) => {
      set((draft) => {
        draft.previewChildId = previewChild.id;
        draft.selectedChildInFamilyId = previewChild.id;
        draft.awards = previewChild.awards ?? {};
        draft.memberships = previewChild.memberships ?? [];
        draft.memoryBoost = previewChild.memoryBoost ?? {};
        draft.accountLinkingToken = previewChild.accountLinkingToken ?? '';
      });
    },

    initFamily: (family) => {
      set((draft) => {
        draft.familyId = family.id;
        draft.familyName = family.name ?? '';
        draft.selectedChildInFamilyId = family.pupils[0]?.id;
        draft.orders = family.orders;
        draft.pendingChildren = addDefaultsForPendingChildren(
          family.pendingChildren
        );
        draft.paymentCycleDate = family.paymentCycleDate;
        draft.monthlyCharge = family.monthlyCharge;
        draft.customerId = family.customerId;
        draft.subscriptionId = family.subscriptionId;

        draft.parentsInFamily = (family.parents ?? []).map((parent) => ({
          ...parent,
          _id: parent.id,
        }));

        draft.invitationsFromFamily = (family.openInvitations ?? []).map(
          (i) => ({
            invitationId: i._id,
            email: i.toUserEmail,
            fromUserId: i.fromUserId,
          })
        );
      });
    },

    setOrder: (order) =>
      set((draft) => {
        const newOrders = [...draft.orders];
        newOrders.push(order);

        draft.orders = newOrders;
      }),

    addInvitationsFromFamily: (invitations) =>
      set((draft) => {
        const invitationsToAdd = [].concat(invitations);
        const pendingInvitations = invitationsToAdd.filter(
          (invitation) =>
            draft.invitationsFromFamily.findIndex(
              (existingInvite) => existingInvite.email === invitation.email
            ) === -1
        );
        if (pendingInvitations.length) {
          draft.invitationsFromFamily.push(...pendingInvitations);
        }
      }),
    removeInvitationFromFamily: (invitation) =>
      set((draft) => {
        const invitationIndex = draft.invitationsFromFamily.findIndex(
          (invitationInList) =>
            invitationInList.invitationId === invitation.invitationId
        );
        if (invitationIndex !== -1) {
          draft.invitationsFromFamily.splice(invitationIndex, 1);
        }
      }),

    updatePendingChildren: (updatedChildren = {}) =>
      set((draft) => {
        draft.pendingChildren ??= {};
        draft.pendingChildren = {
          ...draft.pendingChildren,
          ...addDefaultsForPendingChildren(Object.values(updatedChildren)),
        };
      }),

    setPendingChildAccountType: (pupilId) =>
      set((draft) => {
        const pupil = draft.pendingChildren[pupilId];

        if (pupil) {
          pupil.paymentPlan = NONE;
          pupil.pendingPlan = NONE;
          if (pupil.linkingToken !== undefined) {
            delete pupil.linkingToken;
            delete pupil.linkedLearner;
          } else {
            pupil.linkingToken = '';
            pupil.linkedLearner = {};
          }
        }
      }),

    setPendingChildToken: (pupilId, token) =>
      set((draft) => {
        const pupil = draft.pendingChildren[pupilId];
        if (pupil) {
          pupil.linkingToken = token;
        }
      }),

    removePendingChildChanges: () =>
      set((draft) => {
        Object.values(draft.pendingChildren).forEach((pupil) => {
          pupil.paymentPlan = NONE;
          pupil.pendingPlan = NONE;
          delete pupil.linkingToken;
          delete pupil.linkedLearner;
        });
        Object.values(draft.pupils).forEach((pupil) => {
          pupil.pendingPlan = pupil.paymentPlan;
        });
      }),

    setPendingChildLinkedLearner: (pupilId, learner, linkingToken) =>
      set((draft) => {
        const pupil = draft.pendingChildren[pupilId];
        if (pupil) {
          pupil.linkedLearner = { ...learner, linkingToken };
          pupil.linkingToken = linkingToken;

          pupil.paymentPlan = learner.schoolId ? NONE : ALREADY_SUBSCRIBED;

          pupil.pendingPlan = learner.schoolId
            ? MAKE_SELECTION
            : ALREADY_SUBSCRIBED;
        }
      }),

    setPlan: (pupilId, type) =>
      set((draft) => {
        const pupil = draft.pupils[pupilId] ?? draft.pendingChildren[pupilId];
        if (pupil) {
          if (type) pupil.pendingPlan = type;
          else pupil.pendingPlan = NONE;
        }
      }),

    updateSubscription: (pupils) =>
      set((draft) => {
        draft.pendingChildren = {};
        pupils.forEach((p) => {
          draft.pupils[p.id] = {
            ...draft.pupils[p.id],
            ...p,
            pupilId: p.id,
            pendingPlan: p.paymentPlan,
          };
        });
      }),

    addPaymentMethod: (paymentMethod) =>
      set((draft) => {
        draft.paymentMethods.push(paymentMethod);
      }),
  },
});

export default parentsSlice;
