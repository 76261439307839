/* eslint-disable no-param-reassign */
import { activateSocketHandlers } from '../socket-handlers';

const getInitialState = () => ({ socket: null, hasConnectionData: false });

const socketSlice = (set, get) => ({
  sliceName: 'socket',
  getInitialState,
  onConnected: (data) => {
    const { hasConnectionData } = data;
    get().setHasConnectionData(!!hasConnectionData);
  },
  selectors: {
    ...getInitialState(),
    activateActionHandlers: (actions) => {
      return activateSocketHandlers(actions, get);
    },
  },
  mutators: {
    setHasConnectionData: (hasConnectionData) =>
      set((draft) => {
        draft.hasConnectionData = hasConnectionData;
      }),
  },
});

export default socketSlice;
