const getInitialState = () => ({
  memberships: [
    // { type: 'school', id, name }
    // { type: 'family', id, name }
  ],
  accountLinkingToken: '',
});

const membershipsSlice = (set, get) => ({
  sliceName: 'pupilMemberships',
  getInitialState,
  onConnected: (data) => {
    const { memberships, accountLinkingToken } = data;
    if (memberships) get().setMemberships(memberships);
    if (accountLinkingToken) get().setAccountLinkingToken(accountLinkingToken);
  },
  selectors: {
    ...getInitialState(),
  },
});

export default membershipsSlice;
