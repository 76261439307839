export function upperFirst(text) {
  return text[0].toUpperCase() + text.slice(1);
}

export function lowerFirst(text) {
  return text[0].toLowerCase() + text.slice(1);
}

export function camelFromSnake(text) {
  return lowerFirst(
    text
      .toLowerCase()
      .split('_')
      .map((bit) => upperFirst(bit))
      .join('')
  );
}
