/* eslint-disable no-param-reassign */
import calcGoalsRagFromQuiz, { RAG_STATUS } from 'helpers/calc-rag-status';

const getInitialState = () => ({
  diagnosticIsCalculating: false,
  diagnosticQuizAttempt: {},
  inProgressDiagnosticQuizAttemptId: null,
  diagnosticProcessStarted: false,
  currentQuizAttempt: {},
  memoryBoostQuizAttempt: {},
  inProgressMemoryBoostQuizAttemptInfo: null,
  prereqStatus: {},
  quizzes: {},
  recommendedCourse: null,
});

const getFinalScore = ({ correct, incorrect, skipped }) =>
  (100 * correct) / (correct + incorrect + skipped || 1);

const getNewScore = (goalId, attempt, answer) => {
  const score = {
    correct: (attempt.score?.correct ?? 0) + (answer.isCorrectAnswer ? 1 : 0),
    incorrect:
      (attempt.score?.incorrect ?? 0) +
      (!answer.isCorrectAnswer && answer.answerIndex !== -1 ? 1 : 0),
    skipped:
      (attempt.score?.skipped ?? 0) + (answer.answerIndex === -1 ? 1 : 0),
    correctForGoal:
      (attempt.score?.correctForGoal ?? 0) +
      (answer.isCorrectAnswer && answer.goalId === goalId ? 1 : 0),
  };
  score.percentage = getFinalScore(score);
  return score;
};

const getScore = (attempt) => ({
  correct: (attempt.answers || []).filter((a) => a.isCorrectAnswer).length,
  incorrect: (attempt.answers || []).filter(
    (a) => !a.isCorrectAnswer && a.answerIndex !== -1
  ).length,
  skipped: (attempt.answers || []).filter((a) => a.answerIndex === -1).length,
});

const quizzesSlice = (set, get) => ({
  sliceName: 'quizzes',
  getInitialState,
  onConnected: (data) => {
    const {
      setPrereqStatus,
      setRecommendedCourse,
      setInProgressDiagnosticQuizAttemptId,
      setDiagnosticProcessStarted,
      setInProgressMemoryBoostQuizAttemptInfo,
    } = get();
    if (data.prereqStatus) setPrereqStatus(data.prereqStatus);
    if (data.diagnosticProgress) {
      const {
        recommendedCourse,
        inProgressQuizAttemptId,
        diagnosticProcessStarted,
      } = data.diagnosticProgress;
      if (recommendedCourse) setRecommendedCourse(recommendedCourse);
      if (inProgressQuizAttemptId)
        setInProgressDiagnosticQuizAttemptId(inProgressQuizAttemptId);
      if (diagnosticProcessStarted != null)
        setDiagnosticProcessStarted(diagnosticProcessStarted);
    }
    if (data.memoryBoost?.inProgressQuizAttemptInfo) {
      setInProgressMemoryBoostQuizAttemptInfo(
        data.memoryBoost.inProgressQuizAttemptInfo
      );
    }
  },
  selectors: {
    ...getInitialState(),

    getQuiz: (quizId) => get().quizzes[quizId],

    getDiagnosticProgress: () => {
      const { diagnosticProcessStarted, recommendedCourse } = get();
      return (recommendedCourse && 3) || (diagnosticProcessStarted && 2) || 1;
    },
  },
  mutators: {
    addQuiz: (quiz) =>
      set((draft) => {
        draft.quizzes[quiz.id] = quiz;
      }),

    addQuizAttempt: (attempt) =>
      set((draft) => {
        draft.currentQuizAttempt = attempt;
        draft.currentQuizAttempt.currentIndex = attempt.answers?.length ?? 0;
        draft.currentQuizAttempt.score = getScore(attempt);
      }),

    setCurrentQuizAttempt: (attempt) =>
      set((draft) => {
        draft.currentQuizAttempt = attempt;
      }),

    answerQuestion: (goalId, answer) =>
      set((draft) => {
        const { currentQuizAttempt } = draft;
        currentQuizAttempt.currentIndex++;
        currentQuizAttempt.score = getNewScore(
          goalId,
          currentQuizAttempt,
          answer
        );
        (currentQuizAttempt.answers ??= []).push(answer);
      }),
    syncAnswers: (answers) =>
      set((draft) => {
        const { currentQuizAttempt } = draft;
        currentQuizAttempt.answers = answers;
        currentQuizAttempt.score = getScore(currentQuizAttempt);
      }),
    completeQuizAttempt: () =>
      set((draft) => {
        const { currentQuizAttempt } = draft;
        currentQuizAttempt.completionDate = new Date().toJSON();
      }),

    setDiagnosticQuizAttempt: (attempt) =>
      set((draft) => {
        draft.diagnosticQuizAttempt = attempt;
        draft.diagnosticQuizAttempt.currentIndex = attempt.answers?.length ?? 0;
        draft.diagnosticQuizAttempt.score = getScore(attempt);
        if (!attempt.completionDate) {
          draft.inProgressDiagnosticQuizAttemptId = attempt.id;
        }
        draft.diagnosticProcessStarted = true;
      }),

    answerDiagnosticQuestion: (answer) =>
      set((draft) => {
        const { diagnosticQuizAttempt } = draft;
        diagnosticQuizAttempt.currentIndex++;
        diagnosticQuizAttempt.score = getNewScore(
          null,
          diagnosticQuizAttempt,
          answer
        );
        (diagnosticQuizAttempt.answers ??= []).push(answer);
      }),

    syncDiagnosticAnswers: (answers) =>
      set((draft) => {
        const { diagnosticQuizAttempt } = draft;
        diagnosticQuizAttempt.answers = answers;
        diagnosticQuizAttempt.score = getScore(diagnosticQuizAttempt);
      }),

    completeDiagnosticQuizAttempt: (quizAttempt) =>
      set((draft) => {
        const { diagnosticQuizAttempt } = draft;
        diagnosticQuizAttempt.completionDate = new Date().toJSON();
        diagnosticQuizAttempt.marksAvailable = quizAttempt.answers.length;
        diagnosticQuizAttempt.marksAwarded = quizAttempt.answers.filter(
          (answer) => answer.isCorrectAnswer
        ).length;
        draft.inProgressDiagnosticQuizAttemptId = null;
      }),

    resetDiagnosticProcess: () =>
      set((draft) => {
        draft.diagnosticQuizAttempt = {};
        draft.inProgressDiagnosticQuizAttemptId = null;
        draft.diagnosticProcessStarted = false;
        draft.recommendedCourse = null;
      }),

    setMemoryBoostQuizAttempt: (attempt) =>
      set((draft) => {
        draft.memoryBoostQuizAttempt = attempt;
        draft.memoryBoostQuizAttempt.currentIndex =
          attempt.answers?.length ?? 0;
        draft.memoryBoostQuizAttempt.score = getScore(attempt);
        if (!attempt.completionDate) {
          draft.inProgressMemoryBoostQuizAttemptInfo = {
            id: attempt.id,
            startDate: attempt.creationDate,
          };
        }
      }),

    answerMemoryBoostQuestion: (answer) =>
      set((draft) => {
        const { memoryBoostQuizAttempt } = draft;
        memoryBoostQuizAttempt.currentIndex++;
        memoryBoostQuizAttempt.score = getNewScore(
          null,
          memoryBoostQuizAttempt,
          answer
        );
        (memoryBoostQuizAttempt.answers ??= []).push(answer);
      }),

    syncMemoryBoostAnswers: (answers) =>
      set((draft) => {
        const { memoryBoostQuizAttempt } = draft;
        memoryBoostQuizAttempt.answers = answers;
        memoryBoostQuizAttempt.score = getScore(memoryBoostQuizAttempt);
      }),

    completeMemoryBoostQuizAttempt: (quizAttempt) =>
      set((draft) => {
        const { memoryBoostQuizAttempt } = draft;
        memoryBoostQuizAttempt.completionDate = new Date().toJSON();
        memoryBoostQuizAttempt.marksAvailable = quizAttempt.answers.length;
        memoryBoostQuizAttempt.marksAwarded = quizAttempt.answers.filter(
          (answer) => answer.isCorrectAnswer
        ).length;
        draft.inProgressMemoryBoostQuizAttemptInfo = null;
      }),

    updateMemoryBoostResults: (goalId, srsLevel, isCorrectAnswer) =>
      set((draft) => {
        const result = {
          goalId,
          goalName: draft.goals?.[goalId]?.name ?? '',
          srsLevel,
          isCorrectAnswer,
        };

        // Add to todays results
        const { resultsBreakdown } = draft.memoryBoost;
        resultsBreakdown[7] ??= [];
        if (resultsBreakdown[7].length < 5) {
          resultsBreakdown[7].push(result);
        }
      }),

    updatePrereqStatus: (quiz) =>
      set((draft) => {
        const ragStatuses = calcGoalsRagFromQuiz(quiz);

        for (const [goalId, status] of ragStatuses) {
          const prevMax = draft.prereqStatus[goalId] ?? RAG_STATUS.NO_VALUE;
          draft.prereqStatus[goalId] = Math.max(prevMax, status);
        }
      }),
  },
});

export default quizzesSlice;
