/* eslint-disable no-param-reassign */
const getInitialState = () => ({ memoryBoost: {} });

const memoryBoostSlice = (set, get) => ({
  sliceName: 'memoryBoost',
  getInitialState,
  onConnected: (data) => {
    const { memoryBoost } = data;
    if (memoryBoost) get().setMemoryBoost(memoryBoost);
  },
  selectors: {
    ...getInitialState(),
  },
  mutators: {
    setMemoryBoost: (memoryBoost) =>
      set((draft) => {
        const { resultsBreakdown } = memoryBoost;
        const { goals } = get();

        for (const dayResults of resultsBreakdown) {
          for (const result of dayResults) {
            result.goalName = result.goalName || goals[result.goalId]?.name;
          }
        }
        draft.memoryBoost = memoryBoost;
      }),
  },
});

export default memoryBoostSlice;
