import { socketHandlers, userSocketHandlers } from './action-handler-map';

// socket.on maintains a set of functions for each action.
// To make use of that, we need to maintain function identity,
// so we cache the wrapper functions created for each handler.
const handlerCache = {};

// Input: a map of actions to handlers, an action or array of actions,
// and the store's get method.
// Output: an array of handlers.
function getSocketHandlers(actionHandlerMap, actions, get, name = 'default') {
  return [].concat(actions).reduce((soFar, action) => {
    const cacheKey = `${name}_${action}`;
    // If there's a cached function, return that.
    const cachedHandler = handlerCache[cacheKey];
    if (cachedHandler) return soFar.concat([[action, cachedHandler]]);

    // There's no cached function, so get the handler from action-handler-map.
    const handlers = actionHandlerMap[action];

    if (!handlers) {
      throw new Error(
        `The socket handlers for the '${cacheKey}' action could not be found.`
      );
    }

    // Put the wrapped handler into the cache.
    handlerCache[cacheKey] = (data) =>
      [].concat(handlers).forEach((handler) => handler(data, get, action));

    return soFar.concat([[action, handlerCache[cacheKey]]]);
  }, []);
}

function activateHandlers(actionHandlers, actions, get, name, optionalSocket) {
  const socket = optionalSocket ?? get().socket;
  const handlers = getSocketHandlers(actionHandlers, actions, get, name);
  let ret;

  if (socket) {
    handlers.forEach(([action, handler]) => socket.on(action, handler));
    ret = () =>
      handlers.forEach(([action, handler]) => socket.off(action, handler));
  }
  // Should we throw an error or queue the registrations
  // while there is no socket?

  return ret;
}

export function activateSocketHandlers(actions, get) {
  return activateHandlers(socketHandlers, actions, get, 'store');
}

export function activateUserSocketHandlers(actions, get, socket) {
  const handlers = activateHandlers(
    userSocketHandlers,
    actions,
    get,
    'user-store',
    socket
  );
  return handlers;
}
