import { useEffect, Suspense } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import AppRouter from 'app-router';
import { AUTH_CONFIG } from 'app-constants';
import Loading from 'components/loading';
import AuthIframe from 'components/auth-iframe';
import useKeepWebsocketAlive from 'helpers/hooks/use-keep-websocket-alive';
import useStore from 'store';
import useAuthStore from 'store/auth';
import useSocketInit from './hooks/use-socket-init';
import useControlIntercom from './hooks/use-control-intercom';

const authSelector = (state) => [state.jwt, state.user];
const selector = (state) => [state.setIsLoading, state.isAppError];

function App() {
  const history = useHistory();
  const location = useLocation();
  const [jwt, user] = useAuthStore(authSelector, shallow);
  const [setIsLoading, isAppError] = useStore(selector, shallow);
  const { isLoading, initSocket } = useSocketInit();
  useControlIntercom();
  useKeepWebsocketAlive();

  useEffect(() => {
    const onFocus = () => {
      if (isAppError) initSocket(true);
    };

    window.addEventListener('focus', onFocus);
    return () => window.removeEventListener('focus', onFocus);
  }, [isAppError, initSocket]);

  useEffect(() => {
    if (isAppError) {
      setIsLoading(false);
      history.push('/network-issue');
    }
  }, [isAppError, history, setIsLoading]);

  useEffect(() => {
    const shouldRedirect =
      location.pathname.startsWith('/register') ||
      location.pathname.startsWith('/reset-password');

    if (shouldRedirect) {
      const hasFromProduct = location.search.includes('fromProduct');
      const fromProduct = hasFromProduct ? '' : '?fromProduct=tutor';
      window.location.href = `${AUTH_CONFIG.AUTH_URL}${location.pathname}${location.search}${fromProduct}`;
    }
  }, [location]);

  useEffect(() => {
    if (user)
      if (user.subtype === 'schoolGroupAdmin') {
        window.location.href = `${AUTH_CONFIG.AUTH_URL}/login`;
      } else {
        window.dataLayer?.push({
          user_id: user._id,
          creation: user.created,
          user_type: user.subtype,
          user_roles: user.roles,
          school_id: user.schoolId,
          family_ids: user.familyIds,
          event: 'userDetailsSet',
        });
      }
  }, [user]);

  return (
    <>
      <AuthIframe />
      {isLoading || !jwt ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          <AppRouter />
        </Suspense>
      )}
    </>
  );
}

export default App;
