/* eslint-disable no-console */
import pako from 'pako';
import { v4 as uuid } from 'uuid';
import {
  setGlobalValue,
  getGlobalValue,
  toggleGlobalValue,
} from '../helpers/global-values';

const MIN_RECONNECT_DELAY = 250; // Min time to wait between reconnect attempts
const MAX_RECONNECT_DELAY = 20_000;
const RECONNECT_DECAY = 1.5; // Rate of increase of reconnect delay
const CHECK_SOCKET_READY_INTERVAL = 10;
const REQUEST_TIMEOUT_DEFAULT = 30000;
const SUCCESS_STRINGS = ['SUCCESS', 'SUCCEEDED', 'CREATED'];
const FRAGMENT_ID_LENGTH = 36; // Length of UUID

// Messages sent and received are of the following format:
//
// {
//   "action": "message_action"
//   "data": {
//     "foo": "bar",
//   }
// }
//

setGlobalValue('isSocketLoggingEnabled', false);
setGlobalValue('toggleLogs', () => toggleGlobalValue('isSocketLoggingEnabled'));

export default class Socket {
  url = null;
  #socket = null;
  #handlers = {};
  #reconnectTimeout = null;
  #finished = false;
  #messageFragments = {};
  #requests = {};
  #reconnectAttempts = 0;
  #eventHandlers = {};
  #logFilters = [];
  #fragmentSizeReduction = 0;

  constructor(url) {
    this.url = url;
    this.#connect();
  }

  on(action, fn) {
    this.#handlers[action] ??= new Set();
    this.#handlers[action].add(fn);
  }

  off(action, fn) {
    this.#handlers[action]?.delete(fn);
  }

  async send(action, messageData) {
    while (this.#socket?.readyState !== 1) {
      if (this.#finished) return;

      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => {
        setTimeout(resolve, CHECK_SOCKET_READY_INTERVAL);
      });
    }
    const data = { ...messageData, _vms_url: window.location.href };
    this.#logToConsole('#socket.send', { action, data });
    this.#socket.send(JSON.stringify({ action, data }));
  }

  // Include a _vms_requestId property in the outgoing data.
  // Store promise resolve and reject methods against the id.
  request(action, data, options = {}) {
    const requestId = uuid();
    const { timeout = REQUEST_TIMEOUT_DEFAULT } = options;
    const promise = new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error('The request timed out.'));
        delete this.#requests[requestId];
      }, timeout);
      this.#requests[requestId] = { resolve, reject, timeoutId };
    });
    this.send(action, { ...data, _vms_requestId: requestId });
    return promise;
  }

  // Close the underlying socket and remove all event listeners.
  close() {
    this.#logToConsole('SOCKET_CLOSED - forced');
    this.handleEvent('SOCKET_CLOSED', { forced: true });
    if (this.#socket) {
      this.#socket.onopen = null;
      this.#socket.onerror = null;
      this.#socket.onclose = null;
      this.#socket.onmessage = null;
      this.#socket.close();
      this.#socket = null;
    }
    this.#handlers = {};
    this.#reconnectAttempts = 0;
    this.#reconnectTimeout = null;
    this.#finished = true;
  }

  // TODO: Fix eslint configuration to allow conversion of following
  // into private class methods.
  // N.B. onSocket{Event} context will need binding to this.

  #connect = () => {
    this.url.searchParams.set(
      'fragmentSizeReduction',
      this.#fragmentSizeReduction
    );
    this.#logToConsole('#connect', this.url.href);
    this.#socket = new WebSocket(this.url.href);
    this.#socket.onopen = this.#onSocketOpen;
    this.#socket.onerror = this.#onSocketError;
    this.#socket.onclose = this.#onSocketClose;
    this.#socket.onmessage = this.#onSocketMessage;
  };

  #onSocketOpen = () => {
    const event =
      this.#reconnectAttempts > 0 ? 'SOCKET_REOPENED' : 'SOCKET_OPENED';
    this.#logToConsole(event);
    this.handleEvent(event);

    clearTimeout(this.#reconnectTimeout);
    this.#reconnectAttempts = 0; // Now that we're connected, reset re-connection attempts and thus delay between them.
  };

  #onSocketError = (event) => {
    this.#logToConsole(event);

    // If we haven't yet got connection data, try reducing the max size of the
    // server message payloads
    // Would probably be better to instead catch
    //   "Could not decode a text frame as UTF-8" errors
    // which seem to be resolved by smaller payloads
    if (this.url.searchParams.get('requiredData') !== 'none')
      this.#fragmentSizeReduction++;
  };

  #onSocketClose = () => {
    this.#logToConsole('SOCKET_CLOSED - not forced');
    this.handleEvent('SOCKET_CLOSED', { forced: false });
    const delay = Math.min(
      MAX_RECONNECT_DELAY,
      MIN_RECONNECT_DELAY * RECONNECT_DECAY ** this.#reconnectAttempts
    );

    this.#reconnectTimeout = setTimeout(() => {
      if (this.#reconnectAttempts > 4) {
        this.handleEvent('SOCKET_REOPEN_FAILED');
      }
      this.#reconnectAttempts++;
      this.#connect();
    }, delay);
  };

  #onSocketMessage = ({ data }) => {
    const fragMatch = new RegExp(
      `^(FID=.{${FRAGMENT_ID_LENGTH}})?(FIN=0)?([\\s\\S]*)$`
    );
    const [id, isNotFinalFragment, fragment] = data.match(fragMatch).slice(1);

    const fragmentId = id?.slice(4) ?? 'DEFAULT';

    // TODO: Check fragmentId is valid UUID?

    this.#messageFragments[fragmentId] ??= [];
    this.#messageFragments[fragmentId].push(fragment);

    if (isNotFinalFragment) {
      this.#logToConsole('#onSocketMessage - Non-Final Fragment');
      return;
    }

    const deflatedMessage = atob(this.#messageFragments[fragmentId].join(''));
    delete this.#messageFragments[fragmentId];

    let message;
    try {
      const uint8Arr = Uint8Array.from(deflatedMessage, (c) => c.charCodeAt(0));
      const inflated = pako.inflate(uint8Arr, { to: 'string' });
      message = JSON.parse(inflated);
      this.#logToConsole('#onSocketMessage', message);
    } catch (err) {
      this.#logToConsole('#onSocketMessage - Error', err);
      return; // Ignore malformed messages.
    }

    for (const handler of this.#handlers[message.action] ?? []) {
      handler(message.data, message.action);
    }

    // Call promise resolve or reject methods that exist for incoming data.
    const pendingRequest = this.#requests[message.data?.requestId];
    if (pendingRequest) {
      clearTimeout(pendingRequest.timeoutId);
      if (
        SUCCESS_STRINGS.some(
          (suffix) => message.action.slice(-suffix.length - 1) === `_${suffix}`
        )
      ) {
        pendingRequest.resolve(message.data);
      } else {
        pendingRequest.reject(message.data);
      }
      delete this.#requests[message.data.requestId];
    }
  };

  addEventHandler = (name, callback) => {
    this.#eventHandlers[name] ??= [];
    this.#eventHandlers[name].push(callback);
  };

  removeEventHandler = (name, callback) => {
    const index = this.#eventHandlers[name].indexOf(callback);
    if (index !== -1) {
      this.#eventHandlers[name].splice(index, 1);
    }
  };

  handleEvent = (name, data = {}) => {
    for (const handler of this.#eventHandlers[name] ?? []) {
      handler({ type: name, ...data });
    }
  };

  addLogFilter = (filterFn) => {
    if (!this.#logFilters.includes(filterFn)) this.#logFilters.push(filterFn);
  };

  #logToConsole = (message, data = '') => {
    if (!getGlobalValue('isSocketLoggingEnabled')) return;

    if (this.#logFilters.some((fn) => !fn({ message, data }))) return;

    const mutationSafeData =
      data instanceof Error ? data : JSON.parse(JSON.stringify(data));
    console.log(message, mutationSafeData);
  };
}
