/* eslint-disable no-param-reassign */
import { GLOBAL_NAMESPACE } from 'app-constants';

export const getGlobalValue = (key, scope = window) => {
  return scope?.[GLOBAL_NAMESPACE]?.[key];
};

export const setGlobalValue = (key, value, scope = window) => {
  scope[GLOBAL_NAMESPACE] ??= {};
  scope[GLOBAL_NAMESPACE][key] = value;
  return () => delete scope[GLOBAL_NAMESPACE][key];
};

export const toggleGlobalValue = (key, scope = window) => {
  scope[GLOBAL_NAMESPACE] ??= {};
  const v = getGlobalValue(key, scope);
  if (v === undefined || typeof v === 'boolean')
    scope[GLOBAL_NAMESPACE][key] = !v;
};
