import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import useStore from 'store';

// Teachers preview goals in an iframe modal which displays the goal in the pupil app.
// We use a query parameter to signal that this is what we're doing.
const PREVIEW_GOAL_QUERY_PARAM = 'previewGoalId';
const selector = (state) => [state.previewGoalId, state.setPreviewGoalId];

// As well as setting the preview goal id in the store, we maintain the preview goal query
// parameter on navigation so that this context is not lost even if the iframe is reloaded.

function usePreviewGoal() {
  const [previewGoalId, setPreviewGoalId] = useStore(selector, shallow);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryGoalId = query.get(PREVIEW_GOAL_QUERY_PARAM);

    switch (previewGoalId) {
      case undefined:
        setPreviewGoalId(queryGoalId);
        break;
      case queryGoalId:
        break;
      default:
        query.append(PREVIEW_GOAL_QUERY_PARAM, previewGoalId);
        history.replace({ search: query.toString() });
        break;
    }
  }, [previewGoalId, setPreviewGoalId, location, history]);

  const query = new URLSearchParams(location.search);
  const queryGoalId = query.get(PREVIEW_GOAL_QUERY_PARAM);

  return queryGoalId || previewGoalId;
}

export default usePreviewGoal;
