import { ThemeProvider } from '@material-ui/core/styles';
import createTheme from './create-theme';

import {
  colorTutorGreenDark,
  colorTutorGreenDarkFaded,
  colorTutorGreenDarkHover,
  colorTutorGreenLight,
  colorTutorGreenLightFaded,
  colorTutorQuizCorrectGreen,
  colorTutorBackgroundBlue,
  colorTutorBlueGrey,
  colorTutorInfo,
  colorTutorError,
  colorTutorRed,
  colorTutorPink,
  colorTutorPinkLight,
  colorSecondaryDark,
  colorTutorMemoryBoost,
  colorTutorMemoryBoostLight,
  colorTutorMemoryBoostDark,
  colorTutorInfoMain,
  colorTutorInfoLight,
  colorTutorInfoDark,
  colorTutorErrorMain,
  colorTutorErrorLight,
  colorTutorErrorDark,
  colorTutorGoalEncountered,
  colorTutorFocusFive,
  colorTutorOrange,
  colorTutorBlackText,
  colorTutorDiagnosticHover,
  teacherAssignedCourse,
  completedCourse,
  defaultCourse,
} from './colors';

const primaryMainColor = colorTutorGreenDark;
const secondaryMainColor = colorTutorPink;
const secondaryLightColor = `${secondaryMainColor}14`;

const themeOverrides = {
  typography: {
    fontFamily: ['DM Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    color: '#000000',
    // Material UI provides crazy heading sizes in the default theme.
    // Here, we adjust to the W3C recommend sizes.
    h1: {
      fontSize: '1.875rem', // 30px
      fontWeight: 700,
      margin: '24px 0 20px 0',
      color: '#000000',
    },
    h2: { fontSize: '1.25rem', margin: '10px 0 10px 0', fontWeight: 400 }, // 20px
    h3: { fontSize: '1.25rem', margin: '0 0 10px 0', fontWeight: 700 },
    h4: { fontSize: '1.00rem', fontWeight: 400 },
    h5: { fontSize: '0.875rem', fontWeight: 500 },
    h6: { fontSize: '1.313rem', fontWeight: 500 },
    body1: {
      fontSize: 17,
      lineHeight: '22px',
      color: '#000000DE',
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    body2: { fontSize: 14, color: colorTutorGreenDark },
    subtitle1: { fontSize: '1.00rem', fontWeight: 400, color: '#78909C' },
  },
  palette: {
    primary: {
      main: primaryMainColor,
      light: colorTutorGreenLight,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: secondaryMainColor,
      light: secondaryLightColor,
      dark: colorSecondaryDark,
      contrastText: '#FFFFFF',
    },
    error: {
      main: colorTutorError,
    },
    info: {
      main: colorTutorInfo,
      light: `${colorTutorInfo}14`,
    },
    warning: {
      main: colorTutorOrange,
    },
    greenDark: colorTutorGreenDark,
    greenDarkFaded: colorTutorGreenDarkFaded,
    greenLightFaded: colorTutorGreenLightFaded,
    greenLight: colorTutorGreenLight,
    quizCorrectGreen: colorTutorQuizCorrectGreen,
    bodyBackgroundBlue: colorTutorBackgroundBlue,
    blueGrey: colorTutorBlueGrey,
    red: colorTutorRed,
    pink: colorTutorPink,
    pinkLight: colorTutorPinkLight,
    memoryBoost: colorTutorMemoryBoost,
    memoryBoostLight: colorTutorMemoryBoostLight,
    memoryBoostDark: colorTutorMemoryBoostDark,
    infoMain: colorTutorInfoMain,
    infoLight: colorTutorInfoLight,
    infoDark: colorTutorInfoDark,
    errorMain: colorTutorErrorMain,
    errorLight: colorTutorErrorLight,
    errorDark: colorTutorErrorDark,
    blackText: colorTutorBlackText,
    diagnosticButtonHover: colorTutorDiagnosticHover,
    goalEncountered: colorTutorGoalEncountered,
    focusFive: colorTutorFocusFive,
    teacherAssignedCourse,
    completedCourse,
    defaultCourse,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          backgroundColor: 'rgba(30, 136, 229, 0.1)',
        },
        ul: {
          listStyle: 'none', // Bullet list styles defined in BulletList component
        },
      },
    },
    MuiAlert: {
      root: {
        padding: '14px 18px',
      },
      filledSuccess: {
        backgroundColor: primaryMainColor,
      },
      message: {
        color: '#FFFFFF',
        fontSize: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: 0,
      },
    },
    MuiAlertTitle: {
      root: {
        color: '#FFFFFF',
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
    MuiDataGrid: {
      root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        height: 36,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '24px',
        letterSpacing: '0.46px',
        borderRadius: 4,
        padding: '6px 16px',
        '&$disabled': {
          color: '#00000042',
        },
      },
      sizeLarge: {
        height: 42,
        fontSize: 15,
        lineHeight: '26px',
        padding: '8px 22px',
      },
      sizeSmall: {
        height: 30,
        fontSize: 13,
        lineHeight: '22px',
        padding: '4px 10px',
      },
      contained: {
        '&:hover': {
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
        },
        '&$disabled': {
          backgroundColor: '#0000001F',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colorTutorGreenDarkHover,
        },
        '&:focus': {
          backgroundColor: colorTutorGreenDarkHover,
        },
        '&:active': {
          backgroundColor: colorTutorGreenDarkHover,
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: colorSecondaryDark,
        },
        '&:focus': {
          backgroundColor: colorSecondaryDark,
        },
        '&:active': {
          backgroundColor: colorSecondaryDark,
        },
      },
      containedSizeSmall: {
        fontSize: 14,
        padding: '4px 16px',
      },
      outlined: {
        backgroundColor: '#FFFFFF',
      },
      outlinedPrimary: {
        borderColor: primaryMainColor,
        color: primaryMainColor,
        '&:hover': {
          backgroundColor: colorTutorGreenDarkFaded,
        },
        '&:focus': {
          backgroundColor: colorTutorGreenDarkFaded,
        },
        '&:active': {
          backgroundColor: colorTutorGreenDarkFaded,
        },
      },
      outlinedSecondary: {
        borderColor: secondaryMainColor,
        color: secondaryMainColor,
        '&:hover': {
          backgroundColor: secondaryLightColor,
        },
        '&:focus': {
          backgroundColor: secondaryLightColor,
        },
        '&:active': {
          backgroundColor: secondaryLightColor,
        },
      },
      text: {
        padding: '6px 8px',
      },
      textPrimary: {
        backgroundColor: 'transparent',
      },
    },
    MuiCard: {
      root: {
        padding: 18,
        borderRadius: 16,
        boxShadow: '0px -4px 12px rgba(91, 151, 185, 0.08)',
        overflow: 'visible', // override default MUI card styles
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
      },
    },
    MuiCardActions: {
      root: {
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: '14px 16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px 24px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: 20,
      },
    },
  },
};

const ThemeWrapper = ({ children }) => (
  <ThemeProvider theme={createTheme(themeOverrides)}>{children}</ThemeProvider>
);

export default ThemeWrapper;
