import { AUTH_APP_URL } from 'store/auth/constants';

const TokenError = {
  INVALID_TOKEN: 'This token is not valid.',
  ALREADY_ENROLLED: 'You are already enrolled in this course.',
  INSUFFICIENT_SEATS_AVAILABLE: 'There are no seats available in this course.',
  TOKEN_SCHOOL_MISMATCH: 'The token enter does not match any school.',
  UNKNOWN_ERROR: 'An unknown error has occurred, please try again later.',
};
export default TokenError;

export const MIN_NAME_LENGTH = 2;
export const MIN_USERNAME_LENGTH = 4;
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_SPECIAL_CHARS_WHITELIST =
  '!@£#$%^&*(){}<>[]/\\|\'"`,.;:~+=-_';

export const PwdErrorText = {
  INVALID_PWD: 'Your password is invalid', // TODO: this should not be required
  REQUIRED_FIELD_MISSING: 'All fields are required',
  TOO_SHORT: `Your password must contain at least ${PASSWORD_MIN_LENGTH} characters`,
  INVALID_CHARS: `Passwords can contain only letters, numbers and the following special characters: ${PASSWORD_SPECIAL_CHARS_WHITELIST}`,
  DO_NOT_MATCH: 'The passwords you entered do not match',
  MATCH_TEMP_PWD:
    'Your new password must be different from your temporary password.',
  UNKNOWN_SERVER_ERROR:
    'An unknown error has occurred, please try again later.',
  MISSING_PASSWORD: 'Please enter a password',
  MISSING_CONFIRM_PASSWORD: 'Please confirm your password',
};

export const UserType = {
  TEACHER: 'teacher',
  PUPIL: 'pupil',
  PARENT: 'parent',
  ADULT: 'adult',
};

export const REMEMBER_CONFIG = {
  NUM_GOAL_QUESTIONS: 3,
  NUM_RELATED_QUESTIONS: 7,
};

export const GLOBAL_NAMESPACE = 'LASALLE_VMS';

export const API_ENDPOINTS = {
  CAN_INVITE_EMAILS: `${AUTH_APP_URL}/api/institutions/can-invite-emails`,
  APP: AUTH_APP_URL,
};

export const AUTH_CONFIG = {
  AUTH_URL: AUTH_APP_URL,
  SUBSCRIBE_AUTH_STATE: 'SUBSCRIBE_AUTH_STATE',
  AUTH_STATE_UPDATED: 'AUTH_STATE_UPDATED',
};

export const TEACHER_APP = {
  PUPIL_TABLES: {
    DETAIL: { slug: 'detail', title: 'Detail' },
    GOALS: { slug: 'goals', title: 'Goals' },
    QUIZZES: { slug: 'quizzes', title: 'Quizzes' },
    BOOST: { slug: 'boost', title: 'Memory Boost' },
    PRACTICE: { slug: 'practice', title: 'Free Practice' },
  },
};

export const PARENT_APP = {
  MANAGE_ACCOUNT_PAGES: {
    ADULTS: 'adults',
    BILLING: 'go to billing',
  },
};

export const SHARED_APP = {
  WEEK_START_INDEX: 1, // The week starts on Monday.
  COURSE_TYPES: ['topic', 'stage', 'target', 'bespoke'],
  DEFAULT_COURSE_TYPE: 'TOPIC',
};

export const SUCCESS_STRINGS = ['SUCCESS', 'SUCCEEDED', 'CREATED'];

// Invitation constants
export const SELECT_INVITATION = 'SELECT_INVITATION';
export const CREATE_INSTITUTION = 'CREATE_INSTITUTION';
export const JOINED_INSTITUTION_SUCCESS = 'JOINED_INSTITUTION_SUCCESS';
export const CREATE_INSTITUTION_SUCCESS = 'CREATE_INSTITUTION_SUCCESS';
export const CONFIRM_FAMILY_NAME = 'CONFIRM_FAMILY_NAME';
export const CREATE_FAMILY = 'CREATE_FAMILY';
export const JOINED_FAMILY_SUCCESS = 'JOINED_FAMILY_SUCCESS';
