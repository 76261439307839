import { makeStyles } from '@material-ui/core/styles';

const styles = ({ palette }) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    '& .MuiTypography-h1': {
      fontSize: '34px',
      textAlign: 'center',
      marginTop: '50px',
    },
  },
  logo: {
    margin: '69px 0 0 0',
  },
  progress: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiCircularProgress-root': {
      color: palette.greenDark,
    },
  },
});

export default makeStyles(styles);
