/* eslint-disable no-param-reassign */
const NOTIFICATION_DISPLAY_TIME = 10000;

const getInitialState = () => ({ notifications: [], showNotifications: false });

const notificationsSlice = (set, get) => ({
  sliceName: 'notifications',
  getInitialState,
  selectors: {
    ...getInitialState(),
  },
  mutators: {
    setShowNotifications: (show) =>
      set((draft) => {
        const { notifications, removeNotification } = get();
        if (show) {
          notifications.forEach((notification) => {
            setTimeout(() => {
              const { notifications: notifs } = get();
              removeNotification(notifs.indexOf(notification));
            }, NOTIFICATION_DISPLAY_TIME);
          });
        }
        draft.showNotifications = show;
      }),

    setNotifications: (notificationsReceived) =>
      set((draft) => {
        const allNotifications = [...draft.notifications];
        const newNotifications = [].concat(notificationsReceived);

        newNotifications.forEach((notification) => {
          let addNotification = notification;
          if (addNotification.awardName === 'hotStreak') {
            const prevHotStreakNotif = allNotifications.find(
              (n) => n.awardName === 'hotStreak'
            );
            if (prevHotStreakNotif) {
              addNotification = {
                ...addNotification,
                currentXP:
                  prevHotStreakNotif.currentXP + addNotification.currentXP,
                hasHitThreshold:
                  prevHotStreakNotif.hasHitThreshold ||
                  addNotification.hasHitThreshold,
              };
              const replaceIndex = allNotifications.indexOf(prevHotStreakNotif);
              allNotifications[replaceIndex] = addNotification;
            } else {
              allNotifications.push(addNotification);
            }
          } else if (addNotification.awardName === 'bingeWatcher') {
            const prevBingeWatcherNotif = allNotifications.find(
              (n) => n.awardName === 'bingeWatcher'
            );
            if (prevBingeWatcherNotif) {
              addNotification = {
                ...addNotification,
                currentXP:
                  prevBingeWatcherNotif.currentXP + addNotification.currentXP,
                hasHitThreshold:
                  prevBingeWatcherNotif.hasHitThreshold ||
                  addNotification.hasHitThreshold,
              };
              const replaceIndex = allNotifications.indexOf(
                prevBingeWatcherNotif
              );
              allNotifications[replaceIndex] = addNotification;
            } else {
              allNotifications.push(addNotification);
            }
          } else {
            allNotifications.push(addNotification);
          }

          if (draft.showNotifications) {
            setTimeout(() => {
              const { notifications: notifs, removeNotification } = get();
              removeNotification(notifs.indexOf(addNotification));
            }, NOTIFICATION_DISPLAY_TIME);
          }
        });
        draft.notifications = allNotifications;
      }),

    removeNotification: (index) =>
      set((draft) => {
        draft.notifications.splice(index, 1);
      }),
  },
});

export default notificationsSlice;
