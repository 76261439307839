export const getSessionStorageValue = (field, defaultValue) => {
  let value = defaultValue;
  const storedData = sessionStorage.getItem(field);
  if (storedData) {
    try {
      value = JSON.parse(storedData);
    } catch (err) {
      // console.log(err);
    }
  }
  return value;
};

// Write a store field to session storage whenever the field value changes.
const subscribeField = (useStore, field) => {
  useStore.subscribe(
    (state) => state[field],
    (data) => {
      if (data) sessionStorage.setItem(field, JSON.stringify(data));
    }
  );
};

// Generate a function that augments existing data
// with a field from session storage.
const createTransform = (field) => (data) => {
  const value = getSessionStorageValue(field, null);
  return value ? { ...data, [field]: value } : data;
};

export const initSessionStorage = (
  subscribeFields = [],
  transformFields = [],
  existingTransforms = []
) => ({
  subscribe: (useStore) => {
    for (const field of subscribeFields) subscribeField(useStore, field);
  },
  transforms: transformFields
    .map((field) => createTransform(field))
    .concat(existingTransforms),
});
