import { blueGrey, red } from '@material-ui/core/colors';

export const colorTutorGreenDark = '#23942B';
export const colorTutorGreenDarkHover = '#006100';
export const colorTutorGreenDarkFaded = 'rgba(35, 148, 43, 0.12)';
export const colorTutorGreenLightFaded = 'rgba(35, 148, 43, 0.05)';
export const colorTutorBackgroundBlue = 'rgba(30, 136, 229, 0.1)';
export const colorTutorGreenLight = '#5DC659';
export const colorTutorInfo = '#2196F3';
export const colorTutorError = '#F44336';

export const colorTutorQuizCorrectGreen = '#67D146';
export const colorTutorDiagnosticHover = '#FF478E';
export const colorTutorMemoryBoost = '#0C77DA';
export const colorTutorMemoryBoostLight = '#B7D3F4';
export const colorTutorMemoryBoostDark = '#28589C';
export const colorTutorBlueGrey = blueGrey[400];
export const colorTutorInfoMain = '#64B6F7';
export const colorTutorInfoLight = '#2196F31A';
export const colorTutorInfoDark = '#0B79D0';
export const colorTutorErrorMain = '#F44336';
export const colorTutorErrorLight = '#F443361A';
export const colorTutorErrorDark = '#E31B0C';
export const colorTutorRed = red[500];
export const colorTutorPink = '#FF609E';
export const colorTutorPinkLight = 'rgba(255, 96, 158, 0.1)';
export const colorSecondaryDark = '#C82770';
export const colorTutorOrange = '#ED6C02';
export const colorTutorBlackText = 'rgba(0, 0, 0, 0.87)';
export const colorTutorFocusFive = '#25E82C';
export const colorTutorGoalEncountered = '#C7FECA';

export const completedCourse = colorTutorGreenDark;
export const teacherAssignedCourse = '#1E88E5';
export const defaultCourse = '#78909C';
