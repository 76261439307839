// Add any connection data transform functions required to this array
const transforms = [];

export default transforms;

// Transforms are functions that augment or replace parts of the connection data
// in some way.

// (data) => {
//    data.myProperty = 'test';
//    return data;
// }
