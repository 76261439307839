import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import useStore from 'store';

// Teachers preview courses in an iframe modal which displays the course in the pupil app.
// We use a query parameter to signal that this is what we're doing.
const PREVIEW_COURSE_QUERY_PARAM = 'previewCourseId';
const selector = (state) => [state.previewCourseId, state.setPreviewCourseId];

// As well as setting the preview course id in the store, we maintain the preview course query
// parameter on navigation so that this context is not lost even if the iframe is reloaded.

function usePreviewCourse() {
  const [previewCourseId, setPreviewCourseId] = useStore(selector, shallow);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryCourseId = query.get(PREVIEW_COURSE_QUERY_PARAM);

    switch (previewCourseId) {
      case undefined:
        setPreviewCourseId(queryCourseId);
        break;
      case queryCourseId:
        break;
      default:
        query.append(PREVIEW_COURSE_QUERY_PARAM, previewCourseId);
        history.replace({ search: query.toString() });
        break;
    }
  }, [previewCourseId, setPreviewCourseId, location, history]);

  const query = new URLSearchParams(location.search);
  const queryCourseId = query.get(PREVIEW_COURSE_QUERY_PARAM);

  return queryCourseId || previewCourseId;
}

export default usePreviewCourse;
