import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTypography-h1': {
      fontSize: '34px',
      textAlign: 'center',
      marginTop: '50px',
    },
  },
  logo: {
    margin: '69px 0 0 0',
  },
  messageCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '448px',
    marginTop: spacing(10),
    padding: spacing(4),
    '& .MuiTypography-body1': {
      fontSize: '12px',
      marginTop: spacing(2),
      marginBottom: spacing(4),
    },
    '& .MuiTypography-h2': {
      fontWeight: 'bold',
    },
    '& .MuiButton-contained': {
      height: 'min-content',
      boxShadow:
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
    },
    '& .MuiButton-text': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

export default useStyles;
