/* eslint-disable no-param-reassign */
const getInitialState = () => ({
  // Dictionary of goalId to practice results
  // Results object;
  // { goalId, totalCount, correctCount, thisWeekCount, lastWeekCount }
  practice: {},
});

const practiceSlice = (set, get) => ({
  sliceName: 'practice',
  getInitialState,
  mutators: {
    setPracticeResults: (goalId, results) =>
      set((draft) => {
        draft.practice[goalId] = results;
      }),
    setPracticeTotalCount: (goalId, count) =>
      set((draft) => {
        draft.practice[goalId] ??= {};
        draft.practice[goalId].totalCount = count;
      }),
    setPracticeMaxScore: (goalId, maxScore) =>
      set((draft) => {
        draft.practice[goalId] ??= {};
        draft.practice[goalId].maxScore = maxScore;
      }),
  },
});

export default practiceSlice;
