import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import LoginLogo from 'components/login-logo';
import useLogOut from 'helpers/hooks/use-log-out';
import useStyles from './styles';

function NetworkIssue() {
  const classes = useStyles();
  const { logOut } = useLogOut();

  const helpLink =
    'https://intercom.help/complete-mathematics-6ab1acc71873/en/articles/5704647-ensuring-tutor-works-on-your-school-devices-network';

  return (
    <div className={classes.root}>
      <LoginLogo className={classes.logo} />
      <Card className={classes.messageCard}>
        <Typography variant="h2">Error accessing TUTOR</Typography>
        <Typography variant="body1">
          It appears that your network is blocking the websocket mesages we use
          to verify your credentials, and load up the TUTOR website. Click the
          button below to read the support article which outlines how to resolve
          this issue.
        </Typography>
        <CardActions>
          <Button variant="text" onClick={logOut}>
            sign out
          </Button>
          <Button variant="contained" href={helpLink} target="_blank">
            view help article
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

export default NetworkIssue;
