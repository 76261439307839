import {
  colorTutorGreenDark,
  colorTutorGreenDarkFaded,
  colorTutorGreenDarkHover,
  colorTutorGreenLight,
  colorTutorGreenLightFaded,
  colorTutorQuizCorrectGreen,
  colorTutorBackgroundBlue,
  colorTutorBlueGrey,
  colorTutorRed,
  colorTutorPink,
  colorTutorPinkLight,
  colorTutorMemoryBoost,
  colorTutorMemoryBoostLight,
  colorTutorMemoryBoostDark,
  colorTutorInfoMain,
  colorTutorInfoLight,
  colorTutorInfoDark,
  colorTutorErrorMain,
  colorTutorErrorLight,
  colorTutorErrorDark,
  colorTutorGoalEncountered,
  colorTutorFocusFive,
  colorTutorOrange,
  colorTutorBlackText,
  colorTutorDiagnosticHover,
  teacherAssignedCourse,
  completedCourse,
  defaultCourse,
} from './colors';

const primaryMainColor = colorTutorGreenDark;

const themeOverrides = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 320,
      tablet: 768,
      desktop: 1000,
    },
  },
  typography: {
    fontFamily: ['DM Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    color: '#000000',
    // Material UI provides crazy heading sizes in the default theme.
    // Here, we adjust to the W3C recommend sizes.
    h1: {
      fontSize: '1.875rem', // 30px
      fontWeight: 700,
      margin: '24px 0 20px 0',
      color: '#000000',
    },
    h2: { fontSize: '1.25rem', margin: '10px 0 10px 0', fontWeight: 400 }, // 20px
    h3: { fontSize: '1.25rem', margin: '0 0 10px 0', fontWeight: 700 },
    h4: { fontSize: '1.00rem', fontWeight: 400 },
    h5: { fontSize: '0.875rem', fontWeight: 500 },
    h6: { fontSize: '0.67rem' },
    body1: {
      fontSize: 16,
      color: 'rgba(0, 0, 0, 0.54)',
      fontWeight: 400,
      letterSpacing: '0.15px',
    },
    body2: { fontSize: 14, color: colorTutorGreenDark },
    subtitle1: { fontSize: '1.00rem', fontWeight: 400, color: '#78909C' },
  },
  palette: {
    primary: {
      main: primaryMainColor,
      light: colorTutorGreenLight,
      contrastText: '#FFFFFF',
      hover: colorTutorGreenDarkHover,
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#1E88E5',
    },
    warning: {
      main: colorTutorOrange,
    },
    greenDark: colorTutorGreenDark,
    greenDarkFaded: colorTutorGreenDarkFaded,
    greenLightFaded: colorTutorGreenLightFaded,
    greenLight: colorTutorGreenLight,
    quizCorrectGreen: colorTutorQuizCorrectGreen,
    bodyBackgroundBlue: colorTutorBackgroundBlue,
    blueGrey: colorTutorBlueGrey,
    red: colorTutorRed,
    pink: colorTutorPink,
    pinkLight: colorTutorPinkLight,
    memoryBoost: colorTutorMemoryBoost,
    memoryBoostLight: colorTutorMemoryBoostLight,
    memoryBoostDark: colorTutorMemoryBoostDark,
    infoMain: colorTutorInfoMain,
    infoLight: colorTutorInfoLight,
    infoDark: colorTutorInfoDark,
    errorMain: colorTutorErrorMain,
    errorLight: colorTutorErrorLight,
    errorDark: colorTutorErrorDark,
    blackText: colorTutorBlackText,
    diagnosticButtonHover: colorTutorDiagnosticHover,
    goalEncountered: colorTutorGoalEncountered,
    focusFive: colorTutorFocusFive,
    teacherAssignedCourse,
    completedCourse,
    defaultCourse,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          backgroundColor: 'rgba(30, 136, 229, 0.1)',
        },
        ul: {
          listStyle: 'none', // Bullet list styles defined in BulletList component
        },
      },
    },
    MuiDataGrid: {
      root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        padding: '8px 16px',
      },
      contained: {
        height: 40,
        fontSize: 14,
        boxShadow: 'none',
        padding: '8px 16px',
        backgroundColor: primaryMainColor,
        color: '#FFFFFF',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colorTutorGreenDarkHover,
        },
        '&:focus': {
          boxShadow: 'none',
          backgroundColor: colorTutorGreenDarkHover,
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: colorTutorGreenDarkHover,
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      containedSizeSmall: {
        fontSize: 14,
        padding: '4px 16px',
      },
      containedSizeLarge: {
        height: 60,
        fontSize: 18,
        fontWeight: 700,
        padding: '4px 16px',
      },
      outlined: {
        height: 40,
        fontSize: 14,
        boxShadow: 'none',
        padding: '8px 24px',
        backgroundColor: '#FFFFFF',
        borderColor: primaryMainColor,
        color: primaryMainColor,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colorTutorGreenDarkFaded,
        },
        '&:focus': {
          boxShadow: 'none',
          backgroundColor: colorTutorGreenDarkFaded,
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: colorTutorGreenDarkFaded,
        },
      },
      textPrimary: {
        backgroundColor: 'transparent',
      },
      textSizeLarge: {
        height: '60px',
      },
      outlinedSizeLarge: {
        height: '60px',
      },
    },
    MuiCard: {
      root: {
        padding: 18,
        borderRadius: 16,
        boxShadow: '0px -4px 12px rgba(91, 151, 185, 0.08)',
        overflow: 'visible', // override default MUI card style
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
      },
    },
    MuiCardActions: {
      root: {
        padding: 0,
      },
    },
  },
};

export default themeOverrides;
