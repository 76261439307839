// Find the most recent date in an array of date strings and return in JSON format
function mostRecentDate(dates) {
  let mostRecent = 0;
  dates.forEach((date) => {
    if (date) {
      const time = new Date(date).getTime();
      mostRecent = Math.max(time, mostRecent);
    }
  });

  return mostRecent > 0 ? new Date(mostRecent).toJSON() : undefined;
}

export default mostRecentDate;
