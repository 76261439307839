/* eslint-disable no-param-reassign */
const getInitialState = () => ({
  staffInSchool: [],
  invitationsFromSchool: [],
});

const staffSlice = (set, get) => ({
  sliceName: 'staff',
  getInitialState,
  onConnected: (data) => {
    const { employees, invitations } = data;
    if (employees)
      get().setStaffInSchool(employees.map((emp) => ({ ...emp, _id: emp.id })));
    if (invitations)
      get().setInvitationsFromSchool(
        invitations.map((i) => ({
          invitationId: i._id,
          email: i.toUserEmail,
          fromUserId: i.fromUserId,
        }))
      );
  },
  selectors: {
    ...getInitialState(),
  },
  mutators: {
    addUserToStaffInSchool: (user) =>
      set((draft) => {
        const staffUser = draft.staffInSchool.find((u) => u._id === user._id);
        if (!staffUser) {
          draft.staffInSchool.push(user);
        }
      }),
    addInvitationsFromSchool: (invitations) =>
      set((draft) => {
        const invitationsToAdd = [].concat(invitations);
        const pendingInvitations = invitationsToAdd.filter(
          (invitation) =>
            draft.invitationsFromSchool.findIndex(
              (existingInvite) => existingInvite.email === invitation.email
            ) === -1
        );
        if (pendingInvitations.length) {
          draft.invitationsFromSchool.push(...pendingInvitations);
        }
      }),
    removeInvitationFromSchool: (invitation) =>
      set((draft) => {
        const invitationIndex = draft.invitationsFromSchool.findIndex(
          (invitationInList) =>
            invitationInList.invitationId === invitation.invitationId
        );
        if (invitationIndex !== -1) {
          draft.invitationsFromSchool.splice(invitationIndex, 1);
        }
      }),
  },
});

export default staffSlice;
