import { useCallback } from 'react';
import useAuthStore from 'store/auth';
import { AUTH_APP_URL } from 'store/auth/constants';

const authSelector = (state) => state.iframe;

function useLogOut() {
  const iframe = useAuthStore(authSelector);

  const logOut = useCallback(() => {
    if (iframe) {
      iframe.contentWindow.postMessage({ type: 'LOG_OUT' }, AUTH_APP_URL);
    }
  }, [iframe]);

  return { logOut };
}

export default useLogOut;
