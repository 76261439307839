import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as Logo } from './cm-courses-logo.svg';
import useStyles from './styles';

function LoginLogo({ className }) {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, className)}>
      <Logo />
    </div>
  );
}

LoginLogo.defaultProps = {
  className: null,
};

LoginLogo.propTypes = {
  className: PropTypes.string,
};

export default LoginLogo;
