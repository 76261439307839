export function onGetPupilsProgressSuccess(data, get) {
  if (data.pupilsProgress) {
    get().setPupilsProgress(data.pupilsProgress);
  }
}

export function onGetPupilsProgressSuccessUser(data, get) {
  if (data.pupilsProgress) {
    get().setPupilsProgress(data.pupilsProgress);
  }
}

export function onGetPupilsPracticeResultsSuccess(data, get) {
  if (data.results) {
    get().setPupilsPracticeResults(data.results);
  }
}
