import { createMuiTheme } from '@material-ui/core/styles';
// eslint-disable-next-line camelcase
import { unstable_createMuiStrictModeTheme } from '@material-ui/core';

// Using unstable theme for dev mode to avoid annoying warnings in console.
// See issue: https://github.com/mui-org/material-ui/issues/13394
export default function createTheme(theme) {
  return process.env.NODE_ENV === 'production'
    ? createMuiTheme(theme)
    : // eslint-disable-next-line camelcase
      unstable_createMuiStrictModeTheme(theme);
}
