import { useCallback, useEffect, useRef } from 'react';
import { API_ENDPOINTS, AUTH_CONFIG } from 'app-constants';
import useStore from 'store';
import useAuthStore from 'store/auth';

const { setIsLoading } = useStore.getState();
const { setIframe, getIframe, setJwt } = useAuthStore.getState();
const userSelector = (store) => store.user;

const { AUTH_URL, AUTH_STATE_UPDATED, SUBSCRIBE_AUTH_STATE } = AUTH_CONFIG;

const AuthIframe = () => {
  const iframeRef = useRef();
  const jwtRef = useRef(null);
  const user = useAuthStore(userSelector);

  useEffect(() => {
    const iframeEl = iframeRef.current;
    if (iframeEl) {
      iframeEl.onload = () => {
        iframeEl.contentWindow.postMessage(
          { type: SUBSCRIBE_AUTH_STATE },
          AUTH_URL
        );
      };
      setIframe(iframeEl);
    }
  }, [iframeRef]);

  useEffect(() => {
    const onMessage = (e) => {
      if (e.source === window || e.origin !== AUTH_URL) return;
      if (e.data.type !== AUTH_STATE_UPDATED) return;

      const { jwt } = e.data;

      // We get our first jwt when logging in
      // (or when registering/resetting pwd - see next useEffect)
      if (jwt && !jwtRef.current) setIsLoading(true);
      setJwt(jwt);
      jwtRef.current = jwt;

      if (!jwt) redirectToAuth();
    };
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, []);

  // We don't need the loading spinner when registering or resetting
  // passwords; there's always something useful on screen.
  useEffect(() => {
    if (user && user.email && !user.emailVerified && user.subtype !== 'pupil')
      redirectToAuth();
  }, [user]);

  return (
    <iframe
      ref={iframeRef}
      src={AUTH_URL}
      frameBorder="0"
      height="100%"
      width="100%"
      style={{ display: 'none' }}
      title="Intentionally blank"
      aria-hidden="true"
    />
  );
};

export default AuthIframe;

const iframeSelector = (state) => state.iframe;

export const useSendJwtToIframe = () => {
  const iframe = useAuthStore(iframeSelector);
  return useCallback(
    (jwt) => {
      if (jwt && iframe?.contentWindow) {
        iframe.contentWindow.postMessage(
          { type: 'jwt', jwt },
          API_ENDPOINTS.APP
        );
      }
    },
    [iframe?.contentWindow]
  );
};

export const sendJwtToIframe = (jwt) => {
  const iframe = getIframe();
  if (jwt && iframe?.contentWindow)
    iframe.contentWindow.postMessage({ type: 'jwt', jwt }, API_ENDPOINTS.APP);
};

const redirectToAuth = () => {
  window.open(`${AUTH_CONFIG.AUTH_URL}/login?fromProduct=tutor`, '_self');
};
