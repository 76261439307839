export const onProgressUpdated = (data, get) => {
  // data.identifier is:
  //   award name for awards segment
  //   undefined for diagnostic segment
  //   goal id for all other segments
  const { goalId, identifier, type, value } = data;

  switch (type) {
    case 'GOAL_ENCOUNTERED': // user store
      get().updateGoalsEncountered?.(goalId || identifier, value);
      break;
    case 'DIAGNOSTIC_EVENT': // quizzes slice
      get().setRecommendedCourse?.(value.recommendedCourse);
      get().setDiagnosticIsCalculating?.(false);
      break;
    default:
      break;
  }
};
