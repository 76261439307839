const RAG_STATUS = {
  NO_VALUE: -1,
  RED: 0,
  AMBER: 1,
  GREEN: 2,
};

function ragStatusFromScore({ score, available, type }) {
  const percent = (score / available) * 100;

  switch (type) {
    case 'GENERAL_READINESS':
      if (percent < 50) return RAG_STATUS.RED;
      if (percent < 100) return RAG_STATUS.AMBER;
      return RAG_STATUS.GREEN;
    case 'SPECIFIC_READINESS':
    case 'GOAL':
      if (percent < 40) return RAG_STATUS.RED;
      if (percent < 80) return RAG_STATUS.AMBER;
      return RAG_STATUS.GREEN;
    case 'REMEMBER':
    case 'GOAL_PRACTICE': // for working with old spec data - remove this
      if (percent < 40) return RAG_STATUS.RED;
      if (percent < 80) return RAG_STATUS.AMBER;
      return RAG_STATUS.GREEN;
    default:
      throw new Error(`Unrecognised quiz type: ${type}`);
  }
}

function calcGoalScores(quiz) {
  const goalScores = {};

  for (const answer of quiz?.answers ?? []) {
    const id = answer.goalId || quiz.goalId;

    goalScores[id] ??= {
      available: 0,
      score: 0,
      type: quiz.type ?? 'GOAL', // old Goal quizzes don't have type set
    };

    goalScores[id].available++;
    if (answer.isCorrectAnswer) goalScores[id].score++;
  }

  return goalScores;
}

function calcGoalsRagFromQuiz(quiz) {
  const goalScores = calcGoalScores(quiz);
  return Object.entries(goalScores).map(([goalId, score]) => [
    goalId,
    ragStatusFromScore(score),
  ]);
}

export default calcGoalsRagFromQuiz;
export { RAG_STATUS };
