import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import produce, { enableMapSet } from 'immer';
import getCombinedStore from './slices';
import connectionTransforms from './transforms/connection';
import { initSessionStorage } from './session-storage';

// Extend immer to work with Maps and Sets.
enableMapSet();

// Generate connection data transform functions and a subscribe
// function to persist store changes to session storage.
const { transforms, subscribe } = initSessionStorage(
  ['pendingChildren', 'manageSubscriptionUI'],
  ['manageSubscriptionUI'],
  connectionTransforms
);

const store = (set, get) => {
  // Replace zustand's set fn with a version that
  // uses immer first to get the next state to set.
  const immerSet = (fn) => set(produce(fn));

  // Pass in transforms that augment connection data.
  return getCombinedStore(immerSet, get, transforms);
};

// Generate the store hook.
const useStore = create(subscribeWithSelector(store));

// Subscribe to store changes so that chosen fields
// are saved to session storage.
subscribe(useStore);

// Export the value returned by create.
// It's usually imported as 'useStore'.
export default useStore;
