import {
  parseISO,
  differenceInYears,
  differenceInMonths,
  differenceInWeeks,
  differenceInDays,
  endOfDay,
} from 'date-fns';

function formatRelativeDate(date, baseDate) {
  if (!date) {
    return 'Never';
  }

  const endOfDayDate = endOfDay(baseDate);

  const diffInYears = differenceInYears(endOfDayDate, parseISO(date));
  const diffInMonths = differenceInMonths(endOfDayDate, parseISO(date));
  const diffInWeeks = differenceInWeeks(endOfDayDate, parseISO(date));
  const diffInDays = differenceInDays(endOfDayDate, parseISO(date));

  return (
    (diffInYears > 1 && `${diffInYears} years ago`) ||
    (diffInYears === 1 && '1 year ago') ||
    (diffInMonths > 1 && `${diffInMonths} months ago`) ||
    (diffInMonths === 1 && '1 month ago') ||
    (diffInWeeks > 1 && `${diffInWeeks} weeks ago`) ||
    (diffInWeeks === 1 && '1 week ago') ||
    (diffInDays > 1 && `${diffInDays} days ago`) ||
    (diffInDays === 1 && 'Yesterday') ||
    'Today'
  );
}

export default formatRelativeDate;
