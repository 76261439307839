export const AUTHOR_ROLES = ['cm_author', 'external_author'];
export const EDITOR_ROLES = [
  'cm_editor',
  'external_editor',
  'cm_overseer',
  'dev',
];
export const PUPIL_ROLES = ['pupil'];
export const TEACHER_ROLES = ['teacher', 'tutor_instant_access'];
export const PARENT_ROLES = ['tutor_parent'];
export const SCHOOL_ADMIN_ROLES = ['school_admin'];

export const AUTH_APP_URL = process.env.REACT_APP_AUTH_APP_URL;
export const INTERCOM_ENABLED = JSON.parse(
  process.env.REACT_APP_ENABLE_INTERCOM ?? false
);
