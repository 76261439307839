/* eslint-disable no-param-reassign */
const getInitialState = () => ({
  focusFive: {
    sequenceId: null,
    goalIds: [],
    hasFocusFiveGoalsData: false,
    hasDiagnosticData: false,
    diagnosticsJustCompleted: false,
    hasDiagnosticStarted: false,
    hasCompletedIntroduction: false,
    hasDiagnosticsFinished: false,
    allowDoThisLater: false,
    tutorial: {
      id: undefined,
      questions: [],
      currentQuestionIndex: 0,
    },
    diagnosticRound: {
      id: undefined,
      roundCount: 0,
      questions: [],
      currentQuestionIndex: 0,
    },
  },
});

const focusFiveSlice = (set, get) => ({
  sliceName: 'focusFive',
  getInitialState,
  onConnected: (data) => {
    const { focusFive } = data;
    if (focusFive) {
      get().setFocusFive(focusFive);
    }
  },
  selectors: {
    ...getInitialState(),
    getFocusFiveGoals: () => {
      const { goals, focusFive } = get();
      return focusFive.goalIds.map((goalId) => goals[goalId]).filter((g) => g);
    },
  },
  mutators: {
    setFocusFive: (data) =>
      set((draft) => {
        const { focusFive } = data;
        draft.focusFive = focusFive;
        draft.focusFive.hasDiagnosticData = true;
        draft.focusFive.hasFocusFiveGoalsData = true;
        draft.focusFive.hasDiagnosticStarted =
          !!draft.focusFive.tutorial?.questions?.length;
      }),
    setAllowDoThisLater: (allowDoThisLater) =>
      set((draft) => {
        draft.focusFive.allowDoThisLater = allowDoThisLater;
      }),
    setFocusFiveGoals: (data) =>
      set((draft) => {
        const { goalIds, hasDiagnosticStarted, sequenceId } = data;
        draft.focusFive.goalIds = goalIds;
        draft.focusFive.hasDiagnosticStarted = hasDiagnosticStarted;
        draft.focusFive.sequenceId = sequenceId;
        draft.focusFive.hasFocusFiveGoalsData = true;
      }),
    setDiagnosticsJustCompleted: (data) =>
      set((draft) => {
        draft.focusFive.diagnosticsJustCompleted =
          data.diagnosticsJustCompleted;
      }),
    setShowNewGoalsAlert: (show) =>
      set((draft) => {
        draft.focusFive.showNewGoalsAlert = show;
      }),
    answerDiagnosticQuestion: (questionIx, answerIx, correctAnswerIx) =>
      set((draft) => {
        const question = draft.focusFive.diagnosticQuestions[questionIx];
        question.answerIndex = answerIx;
        question.correctAnswerIndex = correctAnswerIx;
        question.isCorrectAnswer = correctAnswerIx === answerIx;
      }),
    addDiagnosticQuestion: (question) =>
      set((draft) => {
        draft.focusFive.diagnosticQuestions.push(question);
      }),
    setDiagnosticQuestions: (questions) =>
      set((draft) => {
        draft.focusFive.diagnosticQuestions = questions;
      }),
    setTutorial: (tutorial) =>
      set((draft) => {
        draft.focusFive.tutorial = tutorial;
        draft.focusFive.hasDiagnosticStarted = true;
      }),
    setCurrentQuestionIndex: (index) =>
      set((draft) => {
        draft.focusFive.tutorial.currentQuestionIndex = index;
      }),
    incrementTutorialQuestionIndex: (index) =>
      set((draft) => {
        draft.focusFive.tutorial.currentQuestionIndex++;
      }),
    setMathsQuestion: (mathsQuestion) =>
      set((draft) => {
        draft.focusFive.tutorial.questions.push(mathsQuestion);
      }),
    setHasCompletedIntroduction: (hasCompleted) =>
      set((draft) => {
        draft.focusFive.hasCompletedIntroduction = hasCompleted;
      }),
    setHasDiagnosticsFinished: (hasFinished) =>
      set((draft) => {
        draft.focusFive.hasDiagnosticsFinished = hasFinished;
      }),
    incrementDiagnosticQuestionIndex: (index) =>
      set((draft) => {
        draft.focusFive.diagnosticRound.currentQuestionIndex++;
      }),
    setDiagnosticRound: (round) =>
      set((draft) => {
        draft.focusFive.diagnosticRound = {
          ...draft.focusFive.diagnosticRound,
          ...round,
        };
      }),

    resetFocusFiveDiagnostics: () =>
      set((draft) => {
        draft.focusFive = getInitialState().focusFive;
      }),
  },
});

export default focusFiveSlice;
