export const onIssueInvitationsSucceeded = (data, get) => {
  if (data?.type === 'family') {
    get().addInvitationsFromFamily(
      data.invitations.map((invitation) => ({
        invitationId: invitation._id,
        email: invitation.toUserEmail,
      }))
    );
  } else {
    get().addInvitationsFromSchool(
      data.invitations.map((invitation) => ({
        invitationId: invitation._id,
        email: invitation.toUserEmail,
      }))
    );
  }
};

export const onWithdrawInvitationSucceeded = (data, get) => {
  if (data?.invitationId) {
    if (data?.type === 'family') {
      get().removeInvitationFromFamily({ invitationId: data.invitationId });
    } else {
      get().removeInvitationFromSchool({ invitationId: data.invitationId });
    }
  }
};

export const onInvitationStatusUpdated = (data, get) => {
  if (data?.invitation?._id) {
    get().removeInvitationFromSchool({ invitationId: data.invitation._id });
  }
  if (data?.user) {
    get().addUserToStaffInSchool(data.user);
  }
};

export const onInvitationWithdrawn = (data, get) => {
  // Maybe just update the invitation, rather than removing it.
  if (data?.invitationId) get().withdrawInvitation(data.invitationId);
};

export const onInvitationIssued = (data, get) => {
  if (data?.invitation) get().issueInvitation(data.invitation);
};
