import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import useAuthStore from 'store/auth';
import useStore from 'store';
import useQuery from 'helpers/hooks/use-query';

const userSelector = (store) => [store.user, store.intercomEnabledForUser];
const uiSelector = (state) => state.isPreviewingCourseInDialog;
const previewCourseSelector = (state) => !!state.previewCourseId;

const dateToUnix = (date) => Math.floor(date.getTime() / 1000);

const getUserProps = (user) => {
  return {
    userId: user._id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email.toLowerCase(),
    createdAt: dateToUnix(new Date(user.created)),
    company: { companyId: user.schoolId },
  };
};

function useControlIntercom() {
  /* eslint-disable react-hooks/exhaustive-deps */
  const { boot, update, shutdown } = useIntercom();

  const [user, intercomEnabledForUser] = useAuthStore(userSelector);
  const isPreviewingCourseInDialog = useStore(uiSelector);
  const inPreviewMode = useStore(previewCourseSelector);
  const query = useQuery();
  const booted = useRef(false);

  const inBespokeCoursePreviewIFrame =
    inPreviewMode && query.get('type') === 'BESPOKE';

  useEffect(() => {
    if (
      intercomEnabledForUser &&
      !isPreviewingCourseInDialog &&
      !inBespokeCoursePreviewIFrame
    ) {
      const props = getUserProps(user);
      if (!booted.current) {
        boot(props);
        booted.current = true;
      } else {
        update(props);
      }
    } else if (booted.current) {
      shutdown();
      booted.current = false;
    }

    return () => {
      if (booted.current) {
        shutdown();
        booted.current = false;
      }
    };
  }, [
    user,
    intercomEnabledForUser,
    isPreviewingCourseInDialog,
    inBespokeCoursePreviewIFrame,
  ]);

  // Send updates to Intercom on URL changes
  // See https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
  const location = useLocation();
  useEffect(() => {
    if (booted.current) {
      update({
        userId: user._id,
        lastRequestAt: dateToUnix(new Date()),
      });
      // test
    }
  }, [location]);
}

export default useControlIntercom;
