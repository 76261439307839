import { parseISO } from 'date-fns';

// Helpers

const hasValue = (v) => !!v?.length;

const handleMissing = (sorter) => (a, b) => {
  // Missing values last
  switch (Number(hasValue(a)) + Number(hasValue(b))) {
    case 0:
      return 0;
    case 1:
      return hasValue(a) ? -1 : 1;
    default:
      return sorter(a, b);
  }
};

const getClosedIntervalTest = (lower, upper) => (v) => lower <= v && v <= upper;

export function calculatePracticePercentage(practice) {
  const totalCount = practice?.totalCount ?? 0;
  const correctCount = practice?.correctCount ?? 0;
  return totalCount ? (correctCount / totalCount) * 100 : 0;
}

// General sort functions

export function localeCompareSort(a, b) {
  return a.localeCompare(b);
}

export function localeCompareLowerCase(a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

export function recencyDateSort(a, b) {
  // Recent first, missing last
  return new Date(b ?? 0) - new Date(a ?? 0);
}

export function numberSort(a, b) {
  return a - b;
}

export const compareTags = (a, b) =>
  a.localeCompare(b, undefined, { numeric: true });

// Specific sort functions

export function coursesSort(a, b) {
  const aName = a.courses
    .map((course) => course.name)
    .sort(localeCompareSort)
    .join(', ');
  const bName = b.courses
    .map((course) => course.name)
    .sort(localeCompareSort)
    .join(', ');

  return handleMissing(localeCompareSort)(aName, bName);
}

export function progressSort(a, b) {
  const aProgress = (a.progress.goalsCompleted / a.progress.totalGoals) * 100;
  const bProgress = (b.progress.goalsCompleted / b.progress.totalGoals) * 100;
  return aProgress - bProgress;
}

export function getStandardSort(field) {
  return (a, b) => {
    const sortFn =
      typeof a[field] === 'string' ? localeCompareLowerCase : numberSort;
    return handleMissing(sortFn)(a[field], b[field]);
  };
}

export function lastActiveSort(a, b) {
  return recencyDateSort(a.lastActive, b.lastActive);
}

export function goalsCompletedSort(
  { progress: aProgress = {} },
  { progress: bProgress = {} }
) {
  return (aProgress.goalsCompleted || 0) - (bProgress.goalsCompleted || 0);
}

export function getGoalsInDateRangeSort(startDate, endDate) {
  const inDateRange = (goal) =>
    getClosedIntervalTest(startDate, endDate)(parseISO(goal.completionDate));

  return ({ completedGoals: a = [] }, { completedGoals: b = [] }) =>
    a.filter(inDateRange).length - b.filter(inDateRange).length;
}

export function fullNameSort(a, b) {
  return (
    localeCompareLowerCase(a.lastName, b.lastName) ||
    localeCompareLowerCase(a.firstName, b.firstName)
  );
}

export function comparePupilsByTags(a, b) {
  return handleMissing(compareTags)(a.tags.join(', '), b.tags.join(', '));
}

export function avgQuizScoreSort(a, b) {
  return numberSort(a.avgQuizScore, b.avgQuizScore);
}

export function mbQuizzesCompletedSort(a, b) {
  return numberSort(
    a.memoryBoost?.quizzesCompleted ?? 0,
    b.memoryBoost?.quizzesCompleted ?? 0
  );
}

export function mbAvgQuizScoreSort(a, b) {
  return numberSort(
    a.memoryBoost?.averageQuizScore ?? 0,
    b.memoryBoost?.averageQuizScore ?? 0
  );
}

export function mbCurrentStreakSort(a, b) {
  return numberSort(
    a.memoryBoost?.currentStreak ?? 0,
    b.memoryBoost?.currentStreak ?? 0
  );
}

export function mbLongestStreakSort(a, b) {
  return numberSort(
    a.memoryBoost?.longestStreak ?? 0,
    b.memoryBoost?.longestStreak ?? 0
  );
}

export function practiceTotalCountSort(a, b) {
  return numberSort(a.practice?.totalCount ?? 0, b.practice?.totalCount ?? 0);
}

export function practiceThisWeekCountSort(a, b) {
  return numberSort(
    a.practice?.thisWeekCount ?? 0,
    b.practice?.thisWeekCount ?? 0
  );
}

export function practiceLastWeekCountSort(a, b) {
  return numberSort(
    a.practice?.lastWeekCount ?? 0,
    b.practice?.lastWeekCount ?? 0
  );
}

export function practiceCorrectAnswerSort(a, b) {
  return numberSort(
    calculatePracticePercentage(a.practice),
    calculatePracticePercentage(b.practice)
  );
}
