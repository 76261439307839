import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import useStore from 'store';

// Teachers preview children in an iframe modal which displays the child in the pupil app.
// We use a query parameter to signal that this is what we're doing.
const PREVIEW_CHILD_QUERY_PARAM = 'previewChildId';
const selector = (state) => [state.previewChildId, state.setPreviewChildId];

// As well as setting the preview child id in the store, we maintain the preview child query
// parameter on navigation so that this context is not lost even if the iframe is reloaded.

function usePreviewChild() {
  const [previewChildId, setPreviewChildId] = useStore(selector, shallow);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryChildId = query.get(PREVIEW_CHILD_QUERY_PARAM);

    switch (previewChildId) {
      case undefined:
        setPreviewChildId(queryChildId);
        break;
      case queryChildId:
        break;
      default:
        query.append(PREVIEW_CHILD_QUERY_PARAM, previewChildId);
        history.replace({ search: query.toString() });
        break;
    }
  }, [previewChildId, setPreviewChildId, location, history]);

  const query = new URLSearchParams(location.search);
  const queryChildId = query.get(PREVIEW_CHILD_QUERY_PARAM);

  return queryChildId || previewChildId;
}

export default usePreviewChild;
