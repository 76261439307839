export const onEnrolPupilsSuccess = (data, get) => {
  const { userIdsByCourseId, enrollerType } = data;
  Object.entries(userIdsByCourseId).forEach(([courseId, userIds]) => {
    get().updatePupilEnrolments(courseId, userIds, enrollerType);
    get().updateCourseEnrolments(courseId, userIds);
  });
};

export const onUnenrolPupilsSuccess = (data, get) => {
  if (data?.userIdsByCourseId) {
    get().unenrolPupilsFromCourses(data.userIdsByCourseId);
  }
};

// The backend returns a userId to parents so that the enrolment
// can be matched with the currently selected child in the dashboard.
const updateEnrolment = (data, get, isUnenrol) => {
  const { course, courseIds, userId, enrollerType } = data;
  const { selectedChildInFamilyId, addEnroledCourse, removeEnroledCourses } =
    get();
  if (
    // the current user is a pupil
    !userId ||
    // or the parent-selected child has been enrolled/unenrolled
    (selectedChildInFamilyId && userId === selectedChildInFamilyId)
  ) {
    isUnenrol
      ? removeEnroledCourses(courseIds || [course.id])
      : addEnroledCourse({ ...course, enrollerType });
  }
};

export const onEnrolSuccess = (data, get) => updateEnrolment(data, get);
export const onUnenrolSuccess = (data, get) => updateEnrolment(data, get, true);

export const onBespokeUpdateSuccess = (data, get) => {
  get().updateCourse(data.course, true);
};

export const onBespokeDeleteSuccess = (data, get) => {
  get().removeCourse({ id: data.courseId });
};

export const onToggleCourseIdeaSuccess = (data, get) => {
  get().toggleCourseIdea(data.course.id, data.course.ideaId);
};
