/* eslint-disable no-param-reassign */
import countGoals from 'helpers/count-goals-in-ideas';

const FIRST_GOAL_ID_IN_CURRICULUM = '8e21241b-44df-a909-8958-bb92f400d6a9';

const typeDecoratedEntries = (type, entries) =>
  entries.map(([key, val]) => [key, { type, ...val }]);

const getInitialState = () => ({
  allCourses: [],
  course: null,
  goals: {},
  ideas: {},
  myCourses: [],
  previewCourseId: undefined,
  previewGoalId: undefined,
});

const coursesSlice = (set, get) => ({
  sliceName: 'courses',
  getInitialState,
  onConnected: (data) => {
    const { enrolledCourses, courses, goals } = data;
    const { setEnrolledCourses, setCourses, setGoals } = get();
    if (enrolledCourses) setEnrolledCourses(enrolledCourses);
    if (courses) setCourses(courses);
    if (goals) setGoals(goals);
  },
  selectors: {
    ...getInitialState(),

    getCourse: (courseId) =>
      get().myCourses.find((c) => c.course?.id === courseId),

    getCourseById: (courseId) =>
      get().allCourses.find((c) => c.id === courseId),

    isIdeaOnCourse: (courseId, ideaId) => {
      const course = get().getCourseById(courseId);
      return course.ideaIds.includes(ideaId);
    },

    getIdea: (id) => get().ideas[id],

    getGoals: (ideaId) => {
      const state = get();
      const idea = state.getIdea(ideaId);
      const filteredGoals = [];

      if (idea) {
        idea.goalIds.forEach((goalId) => {
          if (state.goals[goalId]) {
            filteredGoals.push(state.goals[goalId]);
          }
        });
      }

      return filteredGoals;
    },

    getGoal: (goalId, ideaId, noIdeaContext = false) => {
      const idea = get().ideas[ideaId];
      if (!idea && !noIdeaContext) return null;

      const goal = get().goals[goalId];

      return goal;
    },

    getNextAndPrevElement: (goalId, ideaId, courseId) => {
      const { getCourse } = get();
      const { ideas = {} } = getCourse(courseId) ?? {};

      const currIdea = ideas[ideaId];
      if (!currIdea) return null;

      let firstGoalIdInNextIdea;
      let finalGoalIdInPrevIdea;

      const currGoalIx = currIdea.goalIds.indexOf(goalId);
      const nextGoalIdInIdea = currIdea.goalIds[currGoalIx + 1];
      const prevGoalIdInIdea = currIdea.goalIds[currGoalIx - 1];

      const courseIdeaIds = Object.keys(ideas);
      const currIdeaIx = courseIdeaIds.indexOf(ideaId);
      const nextIdeaIdinCourse = courseIdeaIds[currIdeaIx + 1];
      const prevIdeaIdInCourse = courseIdeaIds[currIdeaIx - 1];

      if (!nextGoalIdInIdea && nextIdeaIdinCourse) {
        // set next goal id to first goal in next idea
        const { goalIds } = ideas[courseIdeaIds[currIdeaIx + 1]];
        [firstGoalIdInNextIdea] = goalIds;
      }

      if (!prevGoalIdInIdea && prevIdeaIdInCourse) {
        // if we are on the first goal of an idea, the previous goal will be
        // the last goal of the previous idea
        const { goalIds } = ideas[courseIdeaIds[currIdeaIx - 1]];
        finalGoalIdInPrevIdea = goalIds[goalIds.length - 1];
      }

      return {
        nextGoalIdInIdea,
        prevGoalIdInIdea,
        nextIdeaIdinCourse,
        prevIdeaIdInCourse,
        firstGoalIdInNextIdea,
        finalGoalIdInPrevIdea,
      };
    },

    getPrereqStatusForGoal: (goalId) => {
      const { readinessObjectives } = get().goals[goalId];
      const { prereqStatus } = get();
      return readinessObjectives.reduce((obj, { id }) => {
        const next = obj;
        next[id] = prereqStatus[id];
        return next;
      }, {});
    },
    shouldShowReadiness: (goalId) => {
      const { readinessObjectives } = get().goals[goalId];
      return (
        readinessObjectives.some((obj) => obj.canQuiz) ||
        goalId === FIRST_GOAL_ID_IN_CURRICULUM
      );
    },
  },
  mutators: {
    setCourses: (courses) =>
      set((draft) => {
        const { listOfIdeas } = get();
        draft.allCourses = [];
        draft.myCourses = [];
        draft.otherCourses = [];
        courses.forEach((c) => {
          const thisCourse =
            c.type !== 'BESPOKE'
              ? c
              : Object.assign(c, {
                  ideasCount: c.ideaIds?.length || 0,
                  goalsCount: countGoals(c.ideaIds, listOfIdeas),
                });

          if (thisCourse.token) {
            draft.myCourses.push(thisCourse);
          } else {
            draft.otherCourses.push(thisCourse);
          }
          draft.allCourses.push(thisCourse);
        });
      }),

    setAllCourses: ({ courses }) =>
      set((draft) => {
        draft.allCourses = courses;
      }),

    addCourse: (course) =>
      set((draft) => {
        draft.allCourses.push(course);
      }),

    removeCourse: (course) =>
      set((draft) => {
        draft.allCourses = draft.allCourses.filter((c) => c.id !== course.id);
      }),

    updateCourse: (course) =>
      set((draft) => {
        const fullCourse = draft.allCourses.find((c) => c.id === course.id);
        // TODO: use immer update pattern
        draft.allCourses = draft.allCourses
          .filter((c) => c.id !== course.id)
          .concat({ ...fullCourse, ...course, ideaIds: course.ideaIds || [] });
      }),

    setCourseCompleted: ({
      courseId,
      completionTime,
      wasDiagnosticRecommendedOnCompletion,
    }) =>
      set((draft) => {
        const course = draft.myCourses.find((c) => c.course.id === courseId);
        if (course) {
          course.completionTime = completionTime;
          course.wasDiagnosticRecommendedOnCompletion =
            wasDiagnosticRecommendedOnCompletion;
        }
      }),

    toggleCourseIdea: (courseId, ideaId) => {
      const { getCourseById, updateCourse } = get();
      const course = getCourseById(courseId);
      if (course) {
        const ideaIdSet = new Set(course.ideaIds || []);
        ideaIdSet[ideaIdSet.has(ideaId) ? 'delete' : 'add'](ideaId);
        updateCourse({ ...course, ideaIds: [...ideaIdSet] });
      }
    },

    persistCourse: (courseId) => {
      const { id, name, description, ideaIds, isDraft } =
        get().getCourseById(courseId) || {};
      if (id) {
        get().socket.send('UPDATE_BESPOKE_COURSE', {
          course: {
            id,
            name,
            description,
            ideaIds,
            isDraft,
          },
        });
      }
    },

    setEnrolledCourses: (enrolledCourses) =>
      set((draft) => {
        const ideaEntries = enrolledCourses
          .map((c) => Object.entries(c.ideas))
          .flat();
        const goalEntries = enrolledCourses
          .map((c) => Object.entries(c.goals))
          .flat();

        draft.myCourses = enrolledCourses;
        draft.ideas = Object.fromEntries(
          typeDecoratedEntries('idea', ideaEntries)
        );
        draft.goals = Object.fromEntries(
          typeDecoratedEntries('goal', goalEntries)
        );

        const goalToIdeaDict = Object.values(draft.ideas).reduce(
          (soFar, idea) => {
            for (const goalId of idea.goalIds) {
              soFar[goalId] = idea.id;
            }
            return soFar;
          },
          {}
        );

        for (const goal of Object.values(draft.goals)) {
          goal.ideaId = goalToIdeaDict[goal.id];
        }
      }),

    addEnroledCourse: (course) =>
      set((draft) => {
        const courseIdeaEntries = typeDecoratedEntries(
          'idea',
          Object.entries(course.ideas)
        );
        const courseGoalEntries = typeDecoratedEntries(
          'goal',
          Object.entries(course.goals)
        );
        const allIdeaEntries = Object.entries(draft.ideas).concat(
          courseIdeaEntries
        );
        const allGoalEntries = Object.entries(draft.goals).concat(
          courseGoalEntries
        );

        draft.ideas = Object.fromEntries(allIdeaEntries);
        draft.goals = Object.fromEntries(allGoalEntries);
        draft.myCourses.push(course);
      }),

    removeEnroledCourse: (courseId) => {
      get().removeEnroledCourses([courseId]);
    },

    removeEnroledCourses: (courseIds) =>
      set((draft) => {
        draft.myCourses = draft.myCourses.filter(
          (c) => !courseIds.includes(c.course.id)
        );
      }),

    updatePupilEnrolments: (courseId, userIds, enrollerType) =>
      set((draft) => {
        const course = draft.allCourses.find((c) => c.id === courseId);
        userIds.forEach((id) => {
          if (draft.pupils[id])
            draft.pupils[id].courses.push({ ...course, enrollerType });
        });
      }),

    unenrolPupilsFromCourses: (userIdsByCourseId) =>
      set((draft) => {
        Object.entries(userIdsByCourseId).forEach(([courseId, userIds]) => {
          const course = draft.allCourses.find((c) => c.id === courseId);
          course.enrolments = course.enrolments.filter(
            (userId) => !userIds.includes(userId)
          );
          userIds.forEach((id) => {
            if (draft.pupils[id]) {
              draft.pupils[id].courses = draft.pupils[id].courses.filter(
                (c) => c.id !== courseId
              );
            }
          });
        });
      }),

    updateCourseEnrolments: (courseId, userIds) =>
      set((draft) => {
        const course = draft.allCourses.find((c) => c.id === courseId);
        course.enrolments = (course.enrolments || []).concat(...userIds);
        draft.allCourses = draft.allCourses
          .filter((c) => c.id !== course.id)
          .concat(course);
      }),

    addGoals: (goals) =>
      set((draft) => {
        draft.goals = { ...draft.goals, ...goals };
      }),
  },
});

export default coursesSlice;
