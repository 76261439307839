import CircularProgress from '@material-ui/core/CircularProgress';
import LoginLogo from 'components/login-logo';
import useStyles from './styles';

function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoginLogo className={classes.logo} />
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    </div>
  );
}

export default Loading;
