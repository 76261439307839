export const onLinkLearner = (data, get) => {
  const { pupil, order } = data;
  get().setPupil(pupil.id, pupil, true);
  get().setSelectedChildInFamilyId(pupil.id);
  if (order) get().setOrder(order);
};

export const onUnlinkLearner = (data, get) => {
  get().removePupil(data.id);
};

export const onFamilyNameUpdate = (data, get) => {
  const { setFamilyName, memberships, setMemberships } = get();

  setFamilyName(data.family.familyName);

  if (memberships.length) {
    const ix = memberships.findIndex((m) => m.id === data.family.familyId);
    const nextMemberships = memberships;
    nextMemberships[ix].name = data.family.familyName;
    setMemberships(nextMemberships);
  }
};

export const onFamilySubscriptionUpdated = (data, get) => {
  const { updateSubscription } = get();
  if (data?.family?.pupils) updateSubscription(data.family.pupils);
};

export const onFamilyPupilSubscriptionsUpdated = (data, get) => {
  const { updateSubscription } = get();
  if (data?.pupils) updateSubscription(data.pupils);
};

export const onFamilyAddPaymentMethod = (data, get) => {
  const { paymentMethod } = data;
  const { addPaymentMethod, setCurrentPaymentMethod } = get();
  if (paymentMethod) {
    addPaymentMethod(paymentMethod);
    setCurrentPaymentMethod(paymentMethod.id);
  }
};

export const onAddPendingChildren = (data, get) => {
  const { family } = data;
  if (family?.familyPendingChildren)
    get().updatePendingChildren(family.familyPendingChildren);
};
