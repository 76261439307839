/* eslint-disable no-param-reassign */
const getInitialState = () => ({
  iframeHasModal: false,
  isFreeAccount: false,
  isLoading: true,
  isConnecting: false,
  videosPlaying: {},
  isPreviewingCourseInDialog: false,
  isAppError: false,
  manageSubscriptionUI: null,
  hasVisitedTutor: false,
  performGoalCompleteAnimation: false,
});

const uiSlice = (set, get) => ({
  sliceName: 'ui',
  getInitialState,
  onConnected: (data) => {
    if (data.isInstantAccess) get().setIsFreeAccount(true);
    if (data.manageSubscriptionUI)
      get().setManageSubscriptionUI(data.manageSubscriptionUI);
    if (data.hasVisitedTutor) get().setHasVisitedTutor(true);
  },
  selectors: {
    ...getInitialState(),
  },
  mutators: {
    setVideosPlaying: (videoId, isPlaying) =>
      set((draft) => {
        draft.videosPlaying = { ...draft.videosPlaying, [videoId]: isPlaying };
      }),
  },
});

export default uiSlice;
